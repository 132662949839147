const CustomerDetails = () => {
	return (
		<main className='customerDetails'>
			<h1>Customer Details</h1>
			<div className='customerDetails__Container'>
				<div className='customerDetails__Left'>
					<img
						src='https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
						alt='Customer'
					/>
				</div>
				<div className='customerDetails__Right'>
					<div className='customerDetails__Row'>
						<h3>Name :</h3>
						<p>John Doe</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Email :</h3>
						<p>John_Doe@gmail.com</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Phone Number :</h3>
						<p>+91 7651234560</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Address :</h3>
						<p>123 Main St, Springfield, IL 62704</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Total Spent :</h3>
						<p>$1234.56</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Last Purchase Date :</h3>
						<p>2024-06-01</p>
					</div>
					<div className='customerDetails__Row'>
						<h3>Member Since :</h3>
						<p>2024-06-01</p>
					</div>
					<div className='customerDetails__Btn'>
						<button type='button'>Remove</button>
					</div>
				</div>
			</div>
		</main>
	);
};

export default CustomerDetails;
