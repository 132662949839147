import React, { useState } from 'react';

const ShippingSettings = () => {
	const [shippingMethods, setShippingMethods] = useState([
		{
			id: 'sm1',
			name: 'Standard Shipping',
			rate: 5.0,
			zones: ['Zone 1', 'Zone 2'],
		},
		{
			id: 'sm2',
			name: 'Express Shipping',
			rate: 15.0,
			zones: ['Zone 1', 'Zone 3'],
		},
		{
			id: 'sm3',
			name: 'Overnight Shipping',
			rate: 25.0,
			zones: ['Zone 2', 'Zone 3'],
		},
	]);
	const [newMethod, setNewMethod] = useState({
		id: '',
		name: '',
		rate: 0,
		zones: '',
	});

	const handleAddOrUpdateMethod = () => {
		const existingIndex = shippingMethods.findIndex(
			(method) => method.id === newMethod.id
		);
		if (existingIndex >= 0) {
			const updatedMethods = [...shippingMethods];
			updatedMethods[existingIndex] = {
				...newMethod,
				zones: newMethod.zones.split(', '),
			};
			setShippingMethods(updatedMethods);
		} else {
			setShippingMethods([
				...shippingMethods,
				{
					...newMethod,
					zones: newMethod.zones.split(', '),
				},
			]);
		}
		setNewMethod({ id: '', name: '', rate: 0, zones: '' });
	};

	const handleDeleteMethod = (id) => {
		// `if (
		// 	confirm(
		// 		'Are you sure you want to delete this shipping method?'
		// 	)
		// )`
		setShippingMethods(
			shippingMethods.filter((method) => method.id !== id)
		);
	};

	return (
		<div className='mt-4 rounded-sm shadow-sm'>
			<h2 className='text-4xl font-semibold mb-4'>
				Shipping Settings
			</h2>
			<div className='Settings__Table'>
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Method Name</th>
							<th>Rate ($)</th>
							<th>Zones</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{shippingMethods.map((method) => (
							<tr key={method.id}>
								<td>{method.id}</td>
								<td>{method.name}</td>
								<td>${method.rate.toFixed(2)}</td>
								<td>{method.zones.join(', ')}</td>
								<td>
									<button
										className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2'
										onClick={() =>
											setNewMethod({
												...method,
												zones: method.zones.join(', '),
											})
										}>
										Update
									</button>
									<button
										className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'
										onClick={() =>
											handleDeleteMethod(method.id)
										}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className='Settings__Form mt-10'>
				<h3 className='text-3xl font-semibold mb-4'>
					Add / Update Shipping Method
				</h3>
				<div>
					<label htmlFor='id'>ID</label>
					<input
						type='text'
						id='id'
						value={newMethod.id}
						onChange={(e) =>
							setNewMethod({
								...newMethod,
								id: e.target.value,
							})
						}
					/>
				</div>
				<div>
					<label htmlFor='name'>Method Name</label>
					<select
						value={newMethod.name}
						onChange={(e) =>
							setNewMethod({
								...newMethod,
								name: e.target.value,
							})
						}>
						<option value='Standard Shipping'>
							Standard Shipping
						</option>
						<option value='Express Shipping'>
							Express Shipping
						</option>
						<option value='Overnight Shipping'>
							Overnight Shipping
						</option>
					</select>
				</div>
				<div>
					<label htmlFor='rate'>Rate ($)</label>
					<input
						type='number'
						id='rate'
						value={newMethod.rate}
						onChange={(e) =>
							setNewMethod({
								...newMethod,
								rate: parseFloat(e.target.value),
							})
						}
					/>
				</div>
				<div>
					<label htmlFor='zones'>
						Zones (comma separated)
					</label>
					<input
						type='text'
						id='zones'
						value={newMethod.zones}
						onChange={(e) =>
							setNewMethod({
								...newMethod,
								zones: e.target.value,
							})
						}
					/>
				</div>
				<button
					className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
					onClick={handleAddOrUpdateMethod}>
					Save
				</button>
			</div>
		</div>
	);
};

export default ShippingSettings;
