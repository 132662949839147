// import { useState } from 'react';

// const Sidebar = ({ onTabChange }) => {
// 	const [isNotificationsOpen, setIsNotificationsOpen] =
// 		useState(false);
// 	const [isSupportsOpen, setIsSupportsOpen] =
// 		useState(false);
// 	const [isCustomerOpen, setIsCustomerOpen] =
// 		useState(false);

// 	const toggleNotifications = () => {
// 		setIsNotificationsOpen(!isNotificationsOpen);
// 	};

// 	const toggleSupports = () => {
// 		setIsSupportsOpen(!isSupportsOpen);
// 	};

// 	const toggleCustomers = () => {
// 		setIsCustomerOpen(!isCustomerOpen);
// 	};

// 	return (
// 		<aside className='sidebar'>
// 			<div className='sidebar__Container'>
// 				<h1>MENU</h1>
// 				<div className='sidebar__Items'>
// 					<ul className='sidebar__Item'>
// 						<li onClick={() => onTabChange('Dashboard')}>
// 							Dashboard
// 						</li>
// 						<li onClick={() => onTabChange('Orders')}>
// 							Orders
// 						</li>
// 						<li onClick={() => onTabChange('Products')}>
// 							Products
// 						</li>
// 						<li
// 							onClick={toggleCustomers}
// 							className='sidebar__Item--clickable'>
// 							Customers
// 							<svg
// 								xmlns='http://www.w3.org/2000/svg'
// 								fill='none'
// 								viewBox='0 0 24 24'
// 								strokeWidth={3}
// 								stroke='currentColor'
// 								className='size-6'>
// 								<path
// 									strokeLinecap='round'
// 									strokeLinejoin='round'
// 									d='m19.5 8.25-7.5 7.5-7.5-7.5'
// 								/>
// 							</svg>
// 						</li>
// 						{isCustomerOpen && (
// 							<ul className='sidebar__SubItem'>
// 								<li
// 									title='Details'
// 									onClick={() => onTabChange('Details')}>
// 									<p>Details</p>
// 								</li>
// 								<li
// 									title='Manage'
// 									onClick={() => onTabChange('Manage')}>
// 									<p>Manage</p>
// 								</li>
// 							</ul>
// 						)}
// 						<li onClick={() => onTabChange('Marketing')}>
// 							Marketing
// 						</li>
// 						<li onClick={() => onTabChange('Reports')}>
// 							Reports
// 						</li>
// 						<li onClick={() => onTabChange('Settings')}>
// 							Settings
// 						</li>
// 						<li
// 							onClick={toggleNotifications}
// 							className='sidebar__Item--clickable'>
// 							Notifications
// 							<svg
// 								xmlns='http://www.w3.org/2000/svg'
// 								fill='none'
// 								viewBox='0 0 24 24'
// 								strokeWidth={3}
// 								stroke='currentColor'
// 								className='size-6'>
// 								<path
// 									strokeLinecap='round'
// 									strokeLinejoin='round'
// 									d='m19.5 8.25-7.5 7.5-7.5-7.5'
// 								/>
// 							</svg>
// 						</li>
// 						{isNotificationsOpen && (
// 							<ul className='sidebar__SubItem'>
// 								<li
// 									title='System Alerts'
// 									onClick={() =>
// 										onTabChange('System Alerts')
// 									}>
// 									<p>System Alerts</p>
// 								</li>
// 								<li
// 									title='Customer Notifications'
// 									onClick={() =>
// 										onTabChange('Customer Notifications')
// 									}>
// 									<p>Customer Notifications</p>
// 								</li>
// 							</ul>
// 						)}
// 						<li
// 							onClick={toggleSupports}
// 							className='sidebar__Item--clickable'>
// 							Support
// 							<svg
// 								xmlns='http://www.w3.org/2000/svg'
// 								fill='none'
// 								viewBox='0 0 24 24'
// 								strokeWidth={3}
// 								stroke='currentColor'
// 								className='size-6'>
// 								<path
// 									strokeLinecap='round'
// 									strokeLinejoin='round'
// 									d='m19.5 8.25-7.5 7.5-7.5-7.5'
// 								/>
// 							</svg>
// 						</li>
// 						{isSupportsOpen && (
// 							<ul className='sidebar__SubItem'>
// 								<li
// 									title='Customer Support'
// 									onClick={() =>
// 										onTabChange('Customer Support')
// 									}>
// 									<p>Customer Support</p>
// 								</li>
// 								<li
// 									title='Feedback and Reviews'
// 									onClick={() =>
// 										onTabChange('Feedback and Reviews')
// 									}>
// 									<p>Feedback and Reviews</p>
// 								</li>
// 							</ul>
// 						)}
// 					</ul>
// 				</div>
// 			</div>
// 		</aside>
// 	);
// };

// export default Sidebar;


import { LayoutDashboard, Logs, MapPinHouse, CircleUser, LogOut, Download } from "lucide-react";
import { useState } from "react";
import Modal from "../Modal/Modal.component";

const Sidebar = ({ onTabChange, onClick }) => {
	const [open, setOpen] = useState(false);
	return (
		<aside className='sidebar'>
			<div className='sidebar__Container'>
				<h1>MENU</h1>
				<div className='sidebar__Items'>
					<ul className='sidebar__Item'>
						<li onClick={() => onTabChange('Dashboard')}>
							<LayoutDashboard /> Dashboard
						</li>
						<li onClick={() => onTabChange('Orders')}>
							<Logs /> Orders
						</li>
						<li onClick={() => onTabChange('Downloads')}>
							<Download /> Downloads
						</li>
						<li onClick={() => onTabChange('Addresses')}>
							<MapPinHouse /> Addresses
						</li>
						<li onClick={() => onTabChange('Details')}>
							<CircleUser /> Account Details
						</li>
						<li onClick={() => setOpen(true)}>
							<LogOut /> Logout
						</li>
					</ul>
				</div>
			</div>
			<Modal open={open} onClose={setOpen}>
				<div className="logout__Modal w-full flex flex-col items-center justify-center  text-center py-[5rem] px-[5rem]">
					<h1 className="w-full whitespace-nowrap px-[6rem] text-[2.8rem] font-[600]">Confirm Logout?</h1>
					<div className="logout__btn flex items-center justify-between gap-9 pt-[5rem]">
						<button onClick={() => setOpen(false)} className="rounded-full bg-slate-200 px-[4rem] py-[1rem] text-[1.6rem] font-medium">Cancel</button>
						<button className="rounded-full bg-black px-[4rem] py-[1rem] text-[1.6rem] font-medium text-white">Confirm</button>
					</div>
				</div>
			</Modal>
		</aside>
	);
};

export default Sidebar;
