/* eslint-disable class-methods-use-this */
import Cookies from 'js-cookie';
class CookieToken {
	getToken(key) {
		return Cookies.get(key);
	}

	setToken(key, token) {
		Cookies.set(key, token, { expires: 24 / 24 });
	}

	removeToken(key) {
		Cookies.remove(key);
	}
}

export default new CookieToken();
