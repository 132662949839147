import React from 'react';
import CouponsTable from './CouponsTable';
import EmailCampaignsTable from './EmailCampaignsTable';
// import { LineChart } from '@mui/x-charts/LineChart';

const Marketing = () => {
	const couponLabels = [1, 2, 3, 4];
	const salesData = [12, 19, 3, 5];
	const revenueData = [10, 15, 13, 9];

	const emailCampaignLabels = [1, 2, 3, 4];
	const emailCampaignOpenRates = [65, 59, 80, 81];
	const emailCampaignClickThroughRates = [28, 48, 40, 19];

	return (
		<div className='container mx-auto p-4'>
			<div className='mb-4'>
				<h2 className='text-4xl font-bold'>
					Discounts/Coupons
				</h2>
				<CouponsTable />
				<h3 className='text-3xl font-semibold mt-8 mb-4'>
					Usage Statistics
				</h3>
				{/* <LineChart
					xAxis={[{ data: couponLabels }]}
					series={[
						{
							data: salesData,
							label: 'Total Sales',
							color: 'blue',
						},
						{
							data: revenueData,
							label: 'Total Revenue',
							color: 'green',
						},
					]}
					sx={{
						width: '100%',
					}}
					height={500}
				/> */}
			</div>
			<div>
				<h2 className='text-4xl font-bold mb-4'>
					Email Campaigns
				</h2>
				<EmailCampaignsTable />
				<h3 className='text-3xl font-semibold mt-8 mb-4'>
					Performance Metrics
				</h3>
				{/* <LineChart
					xAxis={[{ data: emailCampaignLabels }]}
					series={[
						{
							data: emailCampaignOpenRates,
							label: 'Open Rates',
							color: 'purple',
						},
						{
							data: emailCampaignClickThroughRates,
							label: 'Click-Through Rates',
							color: 'orange',
						},
					]}
					sx={{
						width: '100%',
					}}
					height={500}
				/> */}
			</div>
		</div>
	);
};

export default Marketing;
