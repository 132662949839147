import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
	data: null,
	error: null,
	isLoading: false,
};
const categorySlice = createSlice({
	name: 'category',
	initialState: INITIAL_STATE,
	reducers: {
		fetchCategory(state, action) {
			state.isLoading = true;
		},
		fetchCategorySuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchCategoryFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchCategory,
	fetchCategorySuccess,
	fetchCategoryFailure,
} = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;

const byorSlice = createSlice({
	name: 'byor',
	initialState: INITIAL_STATE,
	reducers: {
		fetchByor(state, action) {
			state.isLoading = true;
		},
		fetchByorSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchByorFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchByor,
	fetchByorSuccess,
	fetchByorFailure,
} = byorSlice.actions;
export const byorReducer = byorSlice.reducer;
