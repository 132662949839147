import React, { useState } from 'react';

const UserManagement = () => {
	const [users, setUsers] = useState([
		{
			id: 'u1',
			name: 'John Doe',
			role: 'Admin',
			permissions: ['Read', 'Write', 'Execute'],
		},
		{
			id: 'u2',
			name: 'Jane Smith',
			role: 'Editor',
			permissions: ['Read', 'Write'],
		},
		{
			id: 'u3',
			name: 'Bob Johnson',
			role: 'Viewer',
			permissions: ['Read'],
		},
	]);
	const [newUser, setNewUser] = useState({
		id: '',
		name: '',
		role: '',
		permissions: '',
	});

	const handleAddOrUpdateUser = () => {
		const existingIndex = users.findIndex(
			(user) => user.id === newUser.id
		);
		if (existingIndex >= 0) {
			const updatedUsers = [...users];
			updatedUsers[existingIndex] = {
				...newUser,
				permissions: newUser.permissions.split(', '),
			};
			setUsers(updatedUsers);
		} else {
			setUsers([
				...users,
				{
					...newUser,
					permissions: newUser.permissions.split(', '),
				},
			]);
		}
		setNewUser({
			id: '',
			name: '',
			role: '',
			permissions: '',
		});
	};

	const handleDeleteUser = (id) => {
		// if (
		// 	// confirm('Are you sure you want to delete this user?')
		// )
		setUsers(users.filter((user) => user.id !== id));
	};

	return (
		<div className='mt-4 rounded-sm shadow-sm'>
			<h2 className='text-4xl font-semibold mb-4'>
				User Management
			</h2>
			<div className='Settings__Table'>
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Role</th>
							<th>Permissions</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user.id}>
								<td>{user.id}</td>
								<td>{user.name}</td>
								<td>{user.role}</td>
								<td>{user.permissions.join(', ')}</td>
								<td>
									<button
										className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2'
										onClick={() =>
											setNewUser({
												...user,
												permissions:
													user.permissions.join(', '),
											})
										}>
										Update
									</button>
									<button
										className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'
										onClick={() =>
											handleDeleteUser(user.id)
										}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className='Settings__Form mt-10'>
				<h3 className='text-3xl font-semibold mb-4'>
					Add / Update User
				</h3>
				<div>
					<label htmlFor='id'>ID</label>
					<input
						type='text'
						id='id'
						value={newUser.id}
						onChange={(e) =>
							setNewUser({ ...newUser, id: e.target.value })
						}
					/>
				</div>
				<div>
					<label htmlFor='name'>Name</label>
					<input
						type='text'
						id='name'
						value={newUser.name}
						onChange={(e) =>
							setNewUser({
								...newUser,
								name: e.target.value,
							})
						}
					/>
				</div>
				<div>
					<label htmlFor='role'>Role</label>
					<select
						value={newUser.role}
						onChange={(e) =>
							setNewUser({
								...newUser,
								role: e.target.value,
							})
						}>
						<option value='Admin'>Admin</option>
						<option value='Editor'>Editor</option>
						<option value='Viewer'>Viewer</option>
					</select>
				</div>
				<div>
					<label htmlFor='permissions'>
						Permissions (comma separated)
					</label>
					<input
						type='text'
						id='permissions'
						value={newUser.permissions}
						onChange={(e) =>
							setNewUser({
								...newUser,
								permissions: e.target.value,
							})
						}
					/>
				</div>
				<button
					className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
					onClick={handleAddOrUpdateUser}>
					Save
				</button>
			</div>
		</div>
	);
};

export default UserManagement;
