import { useState } from 'react';

const customers = [
	{
		customerID: 1,
		name: 'John Doe',
		email: 'john.doe@example.com',
		registrationDate: '2021-01-15',
		orderCount: 5,
		totalSpent: 250.0,
	},
	{
		customerID: 2,
		name: 'Jane Smith',
		email: 'jane.smith@example.com',
		registrationDate: '2020-03-22',
		orderCount: 12,
		totalSpent: 750.5,
	},
	{
		customerID: 3,
		name: 'Alice Johnson',
		email: 'alice.johnson@example.com',
		registrationDate: '2019-07-19',
		orderCount: 8,
		totalSpent: 410.2,
	},
	{
		customerID: 4,
		name: 'Bob Brown',
		email: 'bob.brown@example.com',
		registrationDate: '2018-11-11',
		orderCount: 10,
		totalSpent: 620.0,
	},
	{
		customerID: 5,
		name: 'Charlie Black',
		email: 'charlie.black@example.com',
		registrationDate: '2021-05-05',
		orderCount: 3,
		totalSpent: 120.99,
	},
	{
		customerID: 6,
		name: 'Dana White',
		email: 'dana.white@example.com',
		registrationDate: '2020-09-13',
		orderCount: 15,
		totalSpent: 980.75,
	},
	{
		customerID: 7,
		name: 'Eve Green',
		email: 'eve.green@example.com',
		registrationDate: '2021-02-18',
		orderCount: 7,
		totalSpent: 340.45,
	},
	{
		customerID: 8,
		name: 'Frank Blue',
		email: 'frank.blue@example.com',
		registrationDate: '2019-10-21',
		orderCount: 20,
		totalSpent: 1250.0,
	},
	{
		customerID: 9,
		name: 'Grace Yellow',
		email: 'grace.yellow@example.com',
		registrationDate: '2021-08-09',
		orderCount: 6,
		totalSpent: 290.3,
	},
	{
		customerID: 10,
		name: 'Henry Pink',
		email: 'henry.pink@example.com',
		registrationDate: '2020-06-18',
		orderCount: 9,
		totalSpent: 490.6,
	},
	{
		customerID: 11,
		name: 'Ivy Violet',
		email: 'ivy.violet@example.com',
		registrationDate: '2018-12-29',
		orderCount: 4,
		totalSpent: 200.0,
	},
	{
		customerID: 12,
		name: 'Jack Grey',
		email: 'jack.grey@example.com',
		registrationDate: '2019-11-14',
		orderCount: 18,
		totalSpent: 870.4,
	},
	{
		customerID: 13,
		name: 'Kara Red',
		email: 'kara.red@example.com',
		registrationDate: '2020-04-05',
		orderCount: 11,
		totalSpent: 610.25,
	},
	{
		customerID: 14,
		name: 'Liam Orange',
		email: 'liam.orange@example.com',
		registrationDate: '2021-03-23',
		orderCount: 13,
		totalSpent: 740.85,
	},
	{
		customerID: 15,
		name: 'Mia Purple',
		email: 'mia.purple@example.com',
		registrationDate: '2019-06-17',
		orderCount: 2,
		totalSpent: 80.0,
	},
	{
		customerID: 16,
		name: 'Noah Brown',
		email: 'noah.brown@example.com',
		registrationDate: '2018-07-26',
		orderCount: 16,
		totalSpent: 950.0,
	},
	{
		customerID: 17,
		name: 'Olivia Blue',
		email: 'olivia.blue@example.com',
		registrationDate: '2020-11-03',
		orderCount: 14,
		totalSpent: 800.75,
	},
	{
		customerID: 18,
		name: 'Peter White',
		email: 'peter.white@example.com',
		registrationDate: '2019-09-22',
		orderCount: 6,
		totalSpent: 310.5,
	},
	{
		customerID: 19,
		name: 'Quincy Black',
		email: 'quincy.black@example.com',
		registrationDate: '2021-07-30',
		orderCount: 8,
		totalSpent: 420.9,
	},
	{
		customerID: 20,
		name: 'Rachel Green',
		email: 'rachel.green@example.com',
		registrationDate: '2020-05-14',
		orderCount: 5,
		totalSpent: 230.0,
	},
];

const Customers = () => {
	const [visibleCustomers, setVisibleCustomers] =
		useState(10);
	const [searchQuery, setSearchQuery] = useState('');

	const loadMoreCustomers = () => {
		setVisibleCustomers((prev) =>
			Math.min(prev + 5, customers.length)
		);
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value.toLowerCase());
	};

	const filteredCustomers = customers.filter(
		(customer) =>
			customer.name.toLowerCase().includes(searchQuery) ||
			customer.email.toLowerCase().includes(searchQuery)
	);

	return (
		<main className='customers'>
			<h1>Our Customers</h1>
			<div className='customers__Top'>
				<div className='customers__Searchbar'>
					<input
						type='text'
						placeholder='Search...'
						value={searchQuery}
						onChange={handleSearch}
					/>
				</div>
			</div>
			<div className='customers__Table'>
				<table>
					<thead>
						<tr>
							<th>
								<div>
									Name
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
										/>
									</svg>
								</div>
							</th>
							<th>
								<div>
									Email
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
										/>
									</svg>
								</div>
							</th>
							<th>
								<div>
									Registration Date
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
										/>
									</svg>
								</div>
							</th>
							<th>
								<div>
									Order Count
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
										/>
									</svg>
								</div>
							</th>
							<th>
								<div>
									Total Spent
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='size-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
										/>
									</svg>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredCustomers
							.slice(0, visibleCustomers)
							.map((item) => (
								<tr key={item.customerID}>
									<td title={item.name}>{item.name}</td>
									<td>{item.email}</td>
									<td>{item.registrationDate}</td>
									<td>{item.orderCount}</td>
									<td>$ {item.totalSpent}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			{visibleCustomers < filteredCustomers.length && (
				<div
					className='customers__LoadMore'
					onClick={loadMoreCustomers}>
					<p>Load More...</p>
				</div>
			)}
		</main>
	);
};

export default Customers;
