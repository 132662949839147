export const FooterData = [
	{
		id: 1,
		title: 'Capabilities',
		links: [
			{
				id: 1,
				name: 'Global/Regional Reports',
				url: '/capabilities',
			},
			{
				id: 2,
				name: 'Country Insights',
				url: '/capabilities',
			},
			{
				id: 3,
				name: 'Competitive Landscape',
				url: '/competitive-landscape',
			},
			{
				id: 4,
				name: 'Ad-hoc Research',
				url: '/research',
			},
			{
				id: 5,
				name: 'Industry Outlook',
				url: '/industry-outlook',
			},
			{
				id: 6,
				name: 'Market Database',
				url: '/market-database',
			},
		],
	},
	{
		id: 2,
		title: 'Articles',
		links: [
			{
				id: 1,
				name: 'Blogs',
				url: '/blog',
			},
			{
				id: 2,
				name: 'Industry News',
				url: '/industry-news',
			},
			{
				id: 3,
				name: 'Mega Trends',
				url: '/mega-trends',
			},
			{
				id: 4,
				name: 'Whitepapers',
				url: '/whitepapers',
			},
		],
	},
	{
		id: 3,
		title: 'Company',
		links: [
			{
				id: 1,
				name: 'About Us',
				url: '/about',
			},
			{
				id: 2,
				name: 'Industry News',
				url: '/',
			},
			{
				id: 3,
				name: 'Leadership',
				url: '/teams',
			},
			{
				id: 4,
				name: 'Careers',
				url: '/careers',
			},
			{
				id: 5,
				name: 'Industry Outlook',
				url: '/capabilities',
			},
			{
				id: 6,
				name: 'Contact Us',
				url: '/contact',
			},
		],
	},
];

// ACCORDION DATA :
export const AccordionData = [
	{
		id: 1,
		heading: 'How do I buy a report?',
		details: `You can start by browsing through our categories or using the search function if you have something specific in mind. Once you’ve found the report you need, choose the appropriate license type and add it to your cart. Then, complete the checkout process and a download link will be sent to your inbox.
        If you have any questions along the way or need assistance, don’t hesitate to reach out.We’re here to make this process as smooth as possible for you!`,
	},
	{
		id: 2,
		heading:
			'Do I need an account in order to buy a report?',
		details:
			'While it’s not mandatory to have an HMS account to purchase a report, we strongly encourage it.',
	},
	{
		id: 3,
		heading: 'What is the return and refund policy?',
		details:
			'Due to the nature of the information being sold, we are unable to process returns or issue refunds for products once they have been dispatched.',
	},
	{
		id: 4,
		heading: 'Wondering about customization?',
		details:
			'Don’t worry; we’ve got you covered! When you request customization, our team tailors the report to your specific needs.Whether you’re seeking insights on market size, forecasts, key player market share, segmentation, regulations, trends, or detailed analysis, we’ve got you covered.Once your request is received, one of our research analysts will reach out to you personally to discuss your unique needs in depth.We understand that timely and accurate market data is crucial for decision - making, which is why we’re dedicated to collaborating with you to meet your deadlines and deliver the insights you require.',
	},
];

// HOME Why Choose Us CARD DATA :
export const HomeCardOneData = [
	{
		id: 1,
		url: '/HomeCardImgOne.svg',
		heading: 'Faster turnaround times:',
		details:
			'Get the information you need quickly. We deliver efficient research without sacrificing quality.',
	},
	{
		id: 2,
		url: '/HomeCardImgTwo.svg',
		heading: 'Customizable solutions:',
		details:
			'No one-size-fits-all research here. We tailor our approach to your specific business needs and industry.',
	},
	{
		id: 3,
		url: '/HomeCardImgThree.svg',
		heading: 'Expert human touch:',
		details:
			"Don't get lost in the data. We combine cutting-edge tools with experienced analysis to provide meaningful insights.",
	},
];

// HOME Why Choose Us CARD DATA :
export const HomeServiceCardData = [
	{
		id: 1,
		url: '/ServicesCardIconOne.svg',
		heading: 'Faster turnaround times:',
		details:
			'Get the information you need quickly. We deliver efficient research without sacrificing quality.',
	},
	{
		id: 2,
		url: '/ServicesCardIconTwo.svg',
		heading: 'Customizable solutions:',
		details:
			'No one-size-fits-all research here. We tailor our approach to your specific business needs and industry.',
	},
	{
		id: 3,
		url: '/ServicesCardIconThree.svg',
		heading: 'Expert human touch:',
		details:
			"Don't get lost in the data. We combine cutting-edge tools with experienced analysis to provide meaningful insights.",
	},
	{
		id: 4,
		url: '/ServicesCardIconFour.svg',
		heading: 'Faster turnaround times:',
		details:
			'Get the information you need quickly. We deliver efficient research without sacrificing quality.',
	},
	{
		id: 5,
		url: '/ServicesCardIconFive.svg',
		heading: 'Customizable solutions:',
		details:
			'No one-size-fits-all research here. We tailor our approach to your specific business needs and industry.',
	},
	{
		id: 6,
		url: '/ServicesCardIconSix.svg',
		heading: 'Expert human touch:',
		details:
			"Don't get lost in the data. We combine cutting-edge tools with experienced analysis to provide meaningful insights.",
	},
];

// TEAMS CARD DATA :
export const TeamsCardData = [
	{
		id: 1,
		img: '/TeamsOneImg.jpeg',
		title: 'HRISHIRAJ BHATTACHARJEE',
		subTitle: 'Founder and CEO',
		URL: 'https://www.linkedin.com/in/hrishirajb/',
	},
	{
		id: 2,
		img: '/TeamsTwoImg.jpeg',
		title: 'SAMUEL KENNEDY',
		subTitle: 'Chief Intelligence Officer',
		URL: '',
	},
	{
		id: 3,
		img: '/TeamsThreeImg.jpeg',
		title: 'RAMYABRATA CHAKRABORTY',
		subTitle: 'Senior Vice President',
		URL: 'https://www.linkedin.com/in/ramyabrata-chakraborty-8629161a/',
	},
	{
		id: 4,
		img: '/TeamsFourImg.jpeg',
		title: 'DR. NANDINI DEB',
		subTitle: 'Head, Strategic Communications',
		URL: 'https://www.linkedin.com/in/nandini-deb-4387922b9/',
	},
	{
		id: 5,
		img: '/TeamsFiveImg.jpeg',
		title: 'DR. PARTHANA SARMA',
		subTitle: 'Head, Data Science Division',
		URL: 'https://www.linkedin.com/in/parthana-sarma-7a5874255/',
	},
];

// CAREERS ACCORDION DATA :
export const CareersAccordionData = [
	{
		id: 1,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Art Director',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
	{
		id: 2,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Art Director',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
	{
		id: 3,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Art Director',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
	{
		id: 4,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Art Director',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
	{
		id: 5,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Frontend Developer',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
	{
		id: 6,
		city: 'Karimganj',
		contract: 'Full time',
		role: 'Python Developer',
		details:
			'Implements effective program strategies for our clients, combining pragmatism with ingenuity and innovation. We have an extensive track record of turning business objectives into successful program and acquisition strategies, planning documents, and performance targets.',
	},
];

// Navbar Capabilities Links :
export const capabilitiesLinks = [
	{
		id: 1,
		name: 'GLOBAL/REGIONAL',
		path: '/capabilities',
	},
	{
		id: 2,
		name: 'REPORTS',
		path: '/reports',
	},
	{
		id: 3,
		name: 'COMPETITIVE LANDSCAPE',
		path: '/competitive-landscape',
	},
	{
		id: 4,
		name: 'INDUSTRY OUTLOOK',
		path: '/industry-outlook',
	},
	{
		id: 5,
		name: 'AD-HOC RESEARCH',
		path: '/research',
	},
	{
		id: 6,
		name: 'MARKET DATABASE',
		path: '/market-database',
	},
	{
		id: 7,
		name: 'COUNTRY INSIGHTS',
		path: '/capabilities',
	},
];

// Navbar Company Links :
export const companyLinks = [
	{
		id: 1,
		name: 'About us',
		path: '/company/about',
	},
	{
		id: 2,
		name: 'Meet Our Team',
		path: '/teams',
	},
	{
		id: 3,
		name: 'Careers',
		path: '/company/careers',
	},
];

// Navbar Articles Links :
export const articleLinks = [
	{
		id: 1,
		name: 'Blogs',
		path: '/blog',
	},
	{
		id: 2,
		name: 'Industry News',
		path: '/industry-news',
	},
	{
		id: 3,
		name: 'Mega Trends',
		path: '/mega-trends',
	},
	{
		id: 4,
		name: 'Whitepapers',
		path: '/whitepapers',
	},
];

// Capabilities Page Buttons Text :
export const buttonsData = [
	{ text: 'Finance', id: 1 },
	{ text: 'Chemicals', id: 2 },
	{ text: 'Energy', id: 3 },
	{ text: 'F&B', id: 4 },
	{ text: 'Pharma', id: 5 },
	{ text: 'Conductor', id: 6 },
	{ text: 'A&D', id: 7 },
	{ text: 'ICT', id: 8 },
	{ text: 'Automotive', id: 9 },
];

// Store Page Data :
export const StoreCardsData = [
	{
		id: 1,
		category: 'chemicals',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '100',
	},
	{
		id: 2,
		category: 'chemicals',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '150',
	},
	{
		id: 3,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '120',
	},
	{
		id: 4,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '130',
	},
	{
		id: 5,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '90',
	},
	{
		id: 6,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '85',
	},
	{
		id: 7,
		category: 'chemicals',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '110',
	},
	{
		id: 8,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '140',
	},
	{
		id: 9,
		category: 'discount',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '95',
	},
	{
		id: 10,
		category: 'chemicals',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '115',
	},
	{
		id: 11,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '125',
	},
	{
		id: 12,
		category: 'discount',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '80',
	},
	{
		id: 13,
		category: 'chemicals',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '135',
	},
	{
		id: 14,
		category: 'recent',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '145',
	},
	{
		id: 15,
		category: 'discount',
		// img: StoreCardImg,
		title:
			'3D Imaging Market Size & Share Analysis – Growth Trends, & Forecasts (2024 – 2029)',
		price: '88',
	},
];

// Global/Regional Reports Page Data :
export const globalReportsData = {
	img: "/CapabilitiesBannerImg.svg",
	subDetails: null,
	title: "Global/Regional Reports",
	h3: "GLOBAL REPORTS",
	h1: "At Your",
	span: "Fingertip",
	paraOne: "HMS gathers and analyzes data from nine industry sectors. This data is utilized to generate a thorough document offering a detailed analysis and overview of specific global or regional markets.",
	paraTwo: "These reports are crucial for staying abreast of market dynamics, enabling informed business decision-making, and identifying growth prospects on both global and regional levels.",
	paraThree: "Furthermore, these reports include assessments of the current market size and forecasts future growth or decline based on historical data and market trends."
}

// Country Insights Page Data :
export const countryInsightsData = {
	img: "/counrtyInsightsImg.jpeg",
	title: "Country Insights",
	subDetails: "Welcome to Country Insights, your gateway to a wealth of information about countries around the globe. Whether you're a traveler, researcher, student, or simply curious about the world, our platform provides comprehensive insights into the diverse nations that make up our planet.",
	h3: "COUNTRY INSIGHTS",
	h1: "Country",
	span: "Reports",
	paraOne: "CI reports offer a thorough analysis of a particular country’s economic, political, social, and cultural aspects relevant to the commodities or services under examination.",
	paraTwo: "They aid in recognizing prevailing market trends, emerging opportunities, and foreseeable challenges within the country. ",
	paraThree: "These reports play a critical role in pinpointing diverse potential risks such as economic fluctuations, political uncertainty, regulatory alterations, and other factors that may affect business operations. "
}
