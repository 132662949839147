/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';

// const host = 'http://localhost:8000/api/v1/'; //backend.horizon-markets.com
const host = 'https://server.horizon-markets.com/api/v1/'; //backend.horizon-markets.com

export const apiClient = axios.create({
	baseURL: host,
});

const logOnDev = (message, log) => {
	// if (process.env.NODE_ENV === 'development') {
	console.log(message, log);
	// }
};

apiClient.interceptors.request.use((request) => {
	const { method, url } = request;
	logOnDev(
		`🚀 [${method?.toUpperCase()}] ${url} | Request`,
		request
	);

	return request;
});

apiClient.interceptors.response.use(
	(response) => {
		const { method, url } = response.config;
		const { status } = response;

		logOnDev(
			`✨ [${method?.toUpperCase()}] ${url} | Response ${status}`,
			response
		);

		return response;
	},
	(error) => {
		try {
			const { message } = error;
			const { status, data } = error.response;
			const { method, url } = error.config;
			logOnDev(
				`🚨 [${method?.toUpperCase()}] ${url} | Error ${status} ${
					data?.message || ''
				} | ${message}`,
				error
			);
			return Promise.reject({
				status,
				message: error.response.data.message,
			});
		} catch (error) {
			if (error === 'response is undefined') {
				return Promise.reject({
					status: 503,
					message: 'You are not connected to the server',
				});
			}
		}
	}
);

// for s3 requests
export const axiosClientS3 = axios.create();
