import { useState, useEffect } from 'react';

export function useCountdownTimer() {
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			} else {
				clearInterval(interval);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [seconds]);

	const resetTimer = () => {
		setSeconds(30);
	};

	return [seconds, resetTimer];
}
