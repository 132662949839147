import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
	data: null,
	error: null,
	isLoading: false,
};
const reportSlice = createSlice({
	name: 'report',
	initialState: INITIAL_STATE,
	reducers: {
		fetchReport(state, action) {
			state.isLoading = true;
		},
		fetchReportSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchReportFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchReport,
	fetchReportSuccess,
	fetchReportFailure,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;

const detailedReportSlice = createSlice({
	name: 'detailedReport',
	initialState: INITIAL_STATE,
	reducers: {
		fetchDetailedReport(state, action) {
			state.isLoading = true;
		},
		fetchDetailedReportSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchDetailedReporttFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchDetailedReport,
	fetchDetailedReportSuccess,
	fetchDetailedReportFailure,
} = detailedReportSlice.actions;
export const reportDetailedReducer =
	detailedReportSlice.reducer;

const publishReportSlice = createSlice({
	name: 'publishReport',
	initialState: INITIAL_STATE,
	reducers: {
		postPublishReport(state, action) {
			state.isLoading = true;
		},
		postPublishReportSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		postPublishReportFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	postPublishReport,
	postPublishReportSuccess,
	postPublishReportFailure,
} = publishReportSlice.actions;
export const publishReportReducer =
	publishReportSlice.reducer;
