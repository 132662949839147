import { apiClient } from './apiclient';

export const postInitiateSignin = async (payload) => {
	const apiUrl = '/user/login-initiate';
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const postInitiateSignup = async (payload) => {
	const apiUrl = '/user/register-initiate';

	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const postSignin = async (payload) => {
	const apiUrl = '/user/login';
	console.log(payload);
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: payload,
	});
	return res;
};
export const postSignup = async (payload) => {
	const apiUrl = '/user/register';

	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
