const Button = ({
	type = 'button',
	className,
	text,
	children,
	img,
	onClick,
	disabled = false,
}) => {
	return (
		<button
			type={type}
			className={className}
			onClick={onClick}
			disabled={disabled}>
			{children ? children : text}
			{img && <img src={img} alt='img' />}
		</button>
	);
};

export default Button;
