import { useState } from 'react';

const OrdersActions = () => {
	const [orderId, setOrderId] = useState('');
	const [orderDetails, setOrderDetails] = useState(null);

	// Dummy data for orders (similar to OrdersList data structure)
	const orders = [
		{
			id: '17823642763',
			status: 'Pending',
			total_amount: '$12,565.76',
		},
		{
			id: '78234567234',
			status: 'Processing',
			total_amount: '$8,245.50',
		},
		{
			id: '98347682394',
			status: 'Shipped',
			total_amount: '$5,743.20',
		},
		// Add more dummy data as needed
	];

	const handleOrderIdChange = (e) => {
		setOrderId(e.target.value);
	};

	const handleSearchOrder = () => {
		const foundOrder = orders.find(
			(order) => order.id === orderId
		);
		if (foundOrder) {
			setOrderDetails(foundOrder);
		} else {
			alert(`Order with ID ${orderId} not found.`);
		}
	};

	const handleStatusUpdate = (status) => {
		if (orderDetails) {
			setOrderDetails((prev) => ({
				...prev,
				status: status,
			}));
		}
	};

	const handlePrintInvoice = () => {
		// Logic for printing invoice
		alert(
			`Invoice for Order ID ${orderId} will be printed.`
		);
	};

	const handleIssueRefund = () => {
		if (orderDetails) {
			setOrderDetails((prev) => ({
				...prev,
				status: 'Refund Initiated',
			}));
		}
	};

	return (
		<div className='OrdersActions__Container'>
			<div className='OrdersActions__InputForm'>
				<label>Enter Order ID:</label>
				<input
					type='text'
					value={orderId}
					onChange={handleOrderIdChange}
				/>
				<button onClick={handleSearchOrder}>Search</button>
			</div>

			{orderDetails && (
				<div className='OrdersActions__Result'>
					<h2>Order Details</h2>

					<div className='overflow-x-auto'>
						<table>
							<thead>
								<tr>
									<th>Order ID</th>
									<th>Status</th>
									<th>Total Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{orderDetails.id}</td>
									<td>{orderDetails.status}</td>
									<td>{orderDetails.total_amount}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='mt-[2rem]'>
						<h3 className='text-4xl mb-2'>
							Order Actions:
						</h3>
						<div className='flex items-center gap-6'>
							<button
								onClick={() =>
									handleStatusUpdate('Pending')
								}>
								Pending
							</button>
							<button
								onClick={() =>
									handleStatusUpdate('Processing')
								}>
								Processing
							</button>
							<button
								onClick={() =>
									handleStatusUpdate('Cancelled')
								}>
								Cancelled
							</button>
							<button
								onClick={() =>
									handleStatusUpdate('Shipped')
								}>
								Shipped
							</button>
							<button
								onClick={() =>
									handleStatusUpdate('Delivered')
								}>
								Delivered
							</button>
						</div>

						<div className='flex items-center gap-6 mt-[2.4rem]'>
							<button
								onClick={handlePrintInvoice}
								className='bg-green-300'>
								Print Invoice
							</button>
							<button onClick={handleIssueRefund}>
								Issue Refund
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrdersActions;
