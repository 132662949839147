import { useState } from 'react';

const products = [
	{
		id: '1',
		product_name: 'Product A',
		quantity: 10,
		price: '$100.00',
		payment_method: 'Credit Card',
	},
	{
		id: '2',
		product_name: 'Product B',
		quantity: 5,
		price: '$50.00',
		payment_method: 'PayPal',
	},
	{
		id: '3',
		product_name: 'Product C',
		quantity: 20,
		price: '$75.00',
		payment_method: 'Bank Transfer',
	},
	{
		id: '4',
		product_name: 'Product D',
		quantity: 15,
		price: '$120.00',
		payment_method: 'Credit Card',
	},
	{
		id: '5',
		product_name: 'Product E',
		quantity: 8,
		price: '$90.00',
		payment_method: 'PayPal',
	},
	{
		id: '6',
		product_name: 'Product F',
		quantity: 12,
		price: '$80.00',
		payment_method: 'Credit Card',
	},
	{
		id: '7',
		product_name: 'Product G',
		quantity: 25,
		price: '$60.00',
		payment_method: 'Bank Transfer',
	},
	{
		id: '8',
		product_name: 'Product H',
		quantity: 18,
		price: '$95.00',
		payment_method: 'Credit Card',
	},
	{
		id: '9',
		product_name: 'Product I',
		quantity: 30,
		price: '$110.00',
		payment_method: 'PayPal',
	},
	{
		id: '10',
		product_name: 'Product J',
		quantity: 7,
		price: '$70.00',
		payment_method: 'Bank Transfer',
	},
];

const OrdersDetails = () => {
	const [visibleProducts, setVisibleProducts] = useState(5);
	const [searchQuery, setSearchQuery] = useState('');

	const loadMoreProducts = () => {
		setVisibleProducts((prev) =>
			Math.min(prev + 5, products.length)
		);
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value.toLowerCase());
	};

	const filteredProducts = products.filter(
		(product) =>
			product.product_name
				.toLowerCase()
				.includes(searchQuery) ||
			product.payment_method
				.toLowerCase()
				.includes(searchQuery)
	);

	// const enum_products = ["id", "product_name", "quantity", "price", "payment_method"];

	// const sortByColumn = (index) => {
	//     // sort logic
	// };

	const table_head = [
		'ID',
		'Product Name',
		'Quantity',
		'Price',
		'Payment Method',
	];

	return (
		<main className='orders'>
			<div className='orders__Top'>
				<div className='orders__Searchbar'>
					<input
						type='text'
						placeholder='Search...'
						value={searchQuery}
						onChange={handleSearch}
					/>
				</div>
			</div>
			<div className='orders__Table'>
				<table>
					<thead>
						<tr>
							{table_head.map((title, index) => (
								<th key={index}>
									<div>
										{title}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											className='size-6'
										// onClick={() => sortByColumn(index)}
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
											/>
										</svg>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{filteredProducts
							.slice(0, visibleProducts)
							.map((item) => (
								<tr key={item.id}>
									<td>{item.id}</td>
									<td title={item.product_name}>
										{item.product_name}
									</td>
									<td>{item.quantity}</td>
									<td>{item.price}</td>
									<td>{item.payment_method}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			{visibleProducts < filteredProducts.length && (
				<div
					className='orders__LoadMore'
					onClick={loadMoreProducts}>
					<p>Load More...</p>
				</div>
			)}
		</main>
	);
};

export default OrdersDetails;
