import React, { useState } from 'react';

const SystemAlerts = () => {
	const [orderStatusChanges, setOrderStatusChanges] =
		useState([
			{
				orderId: '001',
				previousStatus: 'Pending',
				currentStatus: 'Shipped',
				timestamp: '2024-07-01 10:00',
			},
			{
				orderId: '002',
				previousStatus: 'Processing',
				currentStatus: 'Delivered',
				timestamp: '2024-07-02 14:30',
			},
			{
				orderId: '003',
				previousStatus: 'Pending',
				currentStatus: 'Cancelled',
				timestamp: '2024-07-03 16:45',
			},
		]);

	const [paymentFailures, setPaymentFailures] = useState([
		{
			paymentId: 'p001',
			orderId: '001',
			reason: 'Insufficient Funds',
			timestamp: '2024-07-01 10:05',
		},
		{
			paymentId: 'p002',
			orderId: '004',
			reason: 'Card Expired',
			timestamp: '2024-07-02 09:15',
		},
		{
			paymentId: 'p003',
			orderId: '005',
			reason: 'Payment Gateway Error',
			timestamp: '2024-07-03 12:20',
		},
	]);

	return (
		<div className='container mx-auto p-4'>
			<h1 className='text-4xl font-bold mb-8'>
				System Alerts
			</h1>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Order Status Changes
				</h2>
				<div className='Notif__Table'>
					<table>
						<thead>
							<tr>
								<th>Order ID</th>
								<th>Previous Status</th>
								<th>Current Status</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{orderStatusChanges.map((change, index) => (
								<tr key={index}>
									<td>{change.orderId}</td>
									<td>{change.previousStatus}</td>
									<td>{change.currentStatus}</td>
									<td>{change.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Payment Failures
				</h2>
				<div className='Notif__Table'>
					<table>
						<thead>
							<tr>
								<th>Payment ID</th>
								<th>Order ID</th>
								<th>Reason</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{paymentFailures.map((failure, index) => (
								<tr key={index}>
									<td>{failure.paymentId}</td>
									<td>{failure.orderId}</td>
									<td>{failure.reason}</td>
									<td>{failure.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default SystemAlerts;
