/* eslint-disable no-unused-vars */
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
// import Token from './token';
import CookieToken from './cookie-token';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../constants/token.constant';
// import { postTokenRefresh } from 'src/repositories/mgmt/mgmt';

// Checking if token is expired.
export const isTokenExpired = (token) => {
	try {
		const tokenExpireTime = jwtDecode(token).exp;
		if (
			moment.unix(tokenExpireTime) - moment(Date.now()) <
			10000
		) {
			return true;
		}
	} catch (err) {
		return false;
	}
	return false;
};

export const loggedIn = () => {
	// Checks if there is a saved token and it's still valid
	const token = CookieToken.getToken(ACCESS_TOKEN_KEY); // Getting token from localstorage
	return !!token && !isTokenExpired(token); // handwaiving here
};

// Clear user token and profile data from localStorage
export const logout = () => {
	localStorage.removeItem(ACCESS_TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
};

// export const TokenValidate = async () => {
//   let access_token = CookieToken.getToken(ACCESS_TOKEN_KEY);
//   let refresh_token = Token.getToken(REFRESH_TOKEN_KEY);
//   if (!refresh_token) return (window.location.href = '/login');

//   let accessTokenExpireTime;

//   try {
//     //extracting the token's expiry time with jwt_decode
//     accessTokenExpireTime = jwtDecode(access_token).exp;
//   } catch (error) {
//     return (window.location.href = '/login');
//   }
//   if (moment.unix(accessTokenExpireTime) - moment(Date.now()) < 10000) {
//     //generating new accessToken
//     let refreshTokenExpireTime;

//     try {
//       refreshTokenExpireTime = jwtDecode(refresh_token).exp;
//     } catch (error) {
//       return (window.location.href = '/login');
//     }
//     if (moment.unix(refreshTokenExpireTime) - moment(Date.now()) > 10000) {
//       return new Promise((resolve, reject) => {
//         postTokenRefresh({ token: refresh_token }).then((res) => {
//           if (!res?.data?.data?.access_token.token) {
//             //the execution will never reach in this block, and if it did, it could be some backend issue.
//             console.log('refresh token is gone');
//             return (window.location.href = '/login');
//           } else {
//             //refreshed the access token
//             const { access_token, refresh_token } = res.data.data;
//             CookieToken.setToken(ACCESS_TOKEN_KEY, access_token.token);
//             Token.setToken(REFRESH_TOKEN_KEY, refresh_token.token);
//             resolve(access_token);
//           }
//         });
//       });
//     } else {
//       //refreshToken expired
//       CookieToken.removeToken(ACCESS_TOKEN_KEY);
//       localStorage.removeItem(REFRESH_TOKEN_KEY);
//       window.location.href = '/login';
//       // alert('Your session has expired, please login again.');
//     }
//     return access_token;
//   }
//   return access_token;
// };
