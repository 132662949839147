import React, {
	forwardRef,
	useEffect,
	useState,
} from 'react';
import axios from 'axios';
import {
	Accordion,
	Hero,
	Banner,
	CardHome,
	CardServiceHome,
	Button,
	CardReportsHome,
	Loader,
} from '../../components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import data from '../../constants/static.json';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectPost } from '../../store/selector/post';
// import { fetchPost } from '../../store/reducers/posts';

const Home = forwardRef((props, ref) => {
	const AccordionData = data.AccordionData;
	const HomeCardOneData = data.HomeCardOneData;
	const HomeServiceCardData = data.HomeServiceCardData;
	const [tab, setTab] = useState('tab-1');
	// const dispatch = useDispatch();
	// const posts =useSelector(selectPost)

	// useEffect(() => {
	// 	dispatch(fetchPost())
	// }, []);

	return (
		<main className='home'>
			<Helmet>
				<title>Home - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
			</Helmet>
			<Hero />
			<section className='home__Reports'>
				<div className='home__Reports--Top'>
					<div className='home__Reports--Left'>
						<p>REPORTS</p>
						<h1>
							Latest <span>Reports</span>
						</h1>
					</div>
					<div className='home__Reports--Right max-lg:hidden'>
						<Button
							text='See All'
							className='button__Common'
						/>
					</div>
				</div>
				<div className='home__Reports--Bottom'>
					{/* {console.log(posts?.posts)}
				{posts?.posts ?<Swiper
						navigation={true}
						modules={[Navigation]}
						slidesPerView={2.5}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						
							{posts.posts?.map((item) => (
								<SwiperSlide
									key={item.id}
									className='min-h-[40rem] bg-[#F4F4F4] flex flex-col gap-[4rem] items-start justify-center'>
									<CardReportsHome
										data={item}
										// onClick={() =>
										// 	navigate(`/reports/${item.id}`)
										// }
									/>
								</SwiperSlide>
							))}
					</Swiper>:null} */}
				</div>
			</section>
			<section className='home__byor'>
				<section className='home__Banner'>
					<Banner
						header='Not getting relevant reports on our site?'
						info='Build your own report as you want.'
						path='/byor'
						btnText='Here'
					/>
				</section>
			</section>
			{/* WORKFLOW */}
			<div className='hidden max-lg:flex max-lg:flex-col my-[5rem] gap-[1.5rem]'>
				<p className='text-[#042E3E] text-[1.6rem] font-[700] text-center uppercase'>
					Workflow
				</p>
				<h1 className='text-[#042E3E] text-[4rem] font-[700] capitalize text-center'>
					Key benefits
				</h1>
			</div>
			<section className='home__Workflow'>
				<div className='home__Workflow--Left'>
					<div className='home__Workflow--Top max-lg:hidden'>
						<p>Workflow</p>
						<h1>
							Key <span>benefits</span>
						</h1>
					</div>
					<div className='home__Workflow__Middle'>
						<div className='home__WorkflowTabs'>
							<ul>
								<li
									onClick={() => setTab('tab-1')}
									className={
										tab === 'tab-1'
											? 'active__Tab'
											: 'default__tab'
									}>
									Competitive Edge
								</li>
								{tab === 'tab-1' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Stay ahead of the curve. Our research
										provides the intelligence you need to
										outmaneuver your competition.
									</div>
								)}
								<li
									onClick={() => setTab('tab-2')}
									className={
										tab === 'tab-2'
											? 'active__Tab'
											: 'default__tab'
									}>
									Minimize risk
								</li>
								{tab === 'tab-2' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Make informed decisions with confidence.
										Our research helps you identify
										potential roadblocks and maximize the
										return on your investments.
									</div>
								)}
								<li
									onClick={() => setTab('tab-3')}
									className={
										tab === 'tab-3'
											? 'active__Tab'
											: 'default__tab'
									}>
									Focus
								</li>
								{tab === 'tab-3' && (
									<div className='home__Workflow--TabData hidden max-lg:block w-full'>
										Let us handle the research. You can
										focus on what you do best - running your
										business.
									</div>
								)}
							</ul>
							{tab === 'tab-1' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Stay ahead of the curve. Our research
									provides the intelligence you need to
									outmaneuver your competition.
								</div>
							)}
							{tab === 'tab-2' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Make informed decisions with confidence.
									Our research helps you identify potential
									roadblocks and maximize the return on your
									investments.
								</div>
							)}
							{tab === 'tab-3' && (
								<div className='home__Workflow--TabData block max-lg:hidden'>
									Let us handle the research. You can focus
									on what you do best - running your
									business.
								</div>
							)}
						</div>
					</div>
					<div className='mt-[6rem] max-lg:flex max-lg:items-center max-lg:justify-center'>
						<Link to='/contact'>
							<Button
								className='button__Common max-lg:rounded-[0.2rem]'
								text='Free Consultation'
							/>
						</Link>
					</div>
				</div>
				<div className='home__Workflow--Right'>
					<img src='/HomeWorkflowImg.svg' alt='Workflow' />
				</div>
			</section>
			{/* Featured Services */}
			<section className='home__Services'>
				<div className='home__Services--Top'>
					<p>SERVICES</p>
					<h1>
						Featured <span>Services</span>
					</h1>
				</div>
				<div className='home__Services--Bottom'>
					<Swiper
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 3.5,
								spaceBetween: 50,
							},
						}}
						modules={[Autoplay]}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						{HomeServiceCardData &&
							HomeServiceCardData.map((item) => (
								<SwiperSlide
									key={item.id}
									className='min-h-[40rem] bg-[#F4F4F4] rounded-[2rem] p-[3rem] flex flex-col gap-[4rem] items-start '>
									<CardServiceHome data={item} />
								</SwiperSlide>
							))}
					</Swiper>
				</div>
			</section>
			{/* Why Choose Us */}
			<section className='home__Wcu'>
				<h1>
					Why <span>choose us?</span>
				</h1>
				<div className='home__Wcu--Cards max-lg:hidden'>
					{HomeCardOneData.map((item) => (
						<CardHome data={item} key={item.id} />
					))}
				</div>
				{/* Slider For Why Choose Us - Responsive View */}
				<div className='home__WcuSlider hidden max-lg:block'>
					<Swiper
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							640: {
								slidesPerView: 1.5,
								spaceBetween: 20,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 10,
							},
							1024: {
								slidesPerView: 3.5,
								spaceBetween: 50,
							},
						}}
						modules={[Autoplay]}
						spaceBetween={30}
						pagination={{ clickable: true }}>
						{HomeCardOneData.map((item) => (
							<SwiperSlide
								key={item.id}
								className='min-h-[40rem] flex flex-col gap-[4rem] items-start '>
								{<CardHome data={item} key={item.id} />}
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</section>
			{/* Banner */}
			<section className='home__Banner'>
				<Banner
					header='Problems come and get solved with ease'
					info="At HMS we are committed to delivering actionable
						insights that empower you to make informed
						decisions and stay ahead of the competition.
						Trust us to provide you with the intelligence
						you need to thrive in today's dynamic
						business landscape."
					path='/'
					btnText='Get Started'
				/>
			</section>
			{/* FAQs */}
			{/* <section
				className='home__Faq'
				ref={ref}
				id='faq-section'>
				<h1>FAQs</h1>
				<div className='home__AccContainer'>
					{AccordionData.map((item) => (
						<Accordion data={item} key={item.id} />
					))}
				</div>
			</section> */}
		</main>
	);
});

export default Home;
