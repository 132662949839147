import { put, takeLatest } from 'redux-saga/effects';
import {
	fetchReport,
	fetchReportFailure,
	fetchReportSuccess,
	fetchDetailedReport,
	fetchDetailedReportFailure,
	fetchDetailedReportSuccess,
	postPublishReportFailure,
	postPublishReport,
	postPublishReportSuccess,
} from '../reducers/report';
import {
	getReports,
	getDetailedReport,
	postReports,
} from '../../repositories/Report';
function* fetchReportSaga(action) {
	try {
		const data = yield getReports(action.payload).then(
			(resp) => resp
		);
		yield put(fetchReportSuccess(data.data));
	} catch (error) {
		yield put(fetchReportFailure(error));
	}
}
export function* watchReportSaga() {
	yield takeLatest(fetchReport.type, fetchReportSaga);
}

function* fetchDetailedReportSaga(action) {
	try {
		const data = yield getDetailedReport(
			action.payload
		).then((resp) => resp);
		yield put(fetchDetailedReportSuccess(data.data));
	} catch (error) {
		yield put(fetchDetailedReportFailure(error));
	}
}
export function* watchDetailedReportSaga() {
	yield takeLatest(
		fetchDetailedReport.type,
		fetchDetailedReportSaga
	);
}

function* publishReportSaga(action) {
	try {
		console.log(action.payload);
		const data = yield postReports(action.payload).then(
			(resp) => resp
		);
		yield put(postPublishReportSuccess(data.data));
	} catch (error) {
		yield put(postPublishReportFailure(error));
	}
}
export function* watchPublishReportSaga() {
	yield takeLatest(
		postPublishReport.type,
		publishReportSaga
	);
}
