import { Helmet } from "react-helmet";

const Careers = () => {
	return (
		<main className='career'>
			<Helmet>
				<title>Careers - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
			</Helmet>
			<div className='career__Container'>
				{/* DETAILS */}
				<div className='career__Top'>
					<h4>SEE OPEN POSITIONS</h4>
					<h1>
						Join our <span>Team</span>
					</h1>
					<p>
						Thank you for your interest in joining our team!
						<br />
						<br />
						At Horizon Market Strategies, we are always on
						the lookout for talented and passionate
						individuals who can contribute to our mission
						and help us grow. While we currently don&apos;t
						have any open positions, we encourage you to
						check back regularly for future opportunities.
					</p>
				</div>
				{/* MORE DETAILS */}
				<div className='career__Bottom'>
					<h4>WHY WORK WITH US?</h4>
					<div className='career__Line'>
						<h4>
							INNOVATIVE ENVIRONMENT:{' '}
							<span>
								Join a team that thrives on creativity and
								innovation. We believe in pushing boundaries
								and thinking outside the box.
							</span>
						</h4>
					</div>
					<div className='career__Line'>
						<h4>
							COLLABORATIVE CULTURE:{' '}
							<span>
								JWe value teamwork and collaboration.
								Working with us means being part of a
								supportive community where your ideas and
								contributions are appreciated.
							</span>
						</h4>
					</div>
					<div className='career__Line'>
						<h4>
							PROFESSIONAL GROWTH:{' '}
							<span>
								Your development matters to us. We provide
								continuous learning opportunities and career
								advancement paths.
							</span>
						</h4>
					</div>
					<div className='career__Line'>
						<h4>
							IMPACTFUL WORK:{' '}
							<span>
								Be a part of projects that make a real
								difference. At HMS, your work will have a
								meaningful impact..
							</span>
						</h4>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Careers;
