import { useState } from 'react';
import { Button } from '../../../components';
import StoreSettings from './StoreSettings';
import PaymentSettings from './PaymentSettings';
import ShippingSettings from './ShippingSettings';
import TaxSettings from './TaxSettings';
import UserManagement from './UserManagement';

const Reports = () => {
	const [view, setView] = useState(0);

	const showView = () => {
		switch (view) {
			case 0:
				return <StoreSettings />;
			case 1:
				return <PaymentSettings />;
			case 2:
				return <ShippingSettings />;
			case 3:
				return <TaxSettings />;
			case 4:
				return <UserManagement />;
		}
	};

	return (
		<div className='Orders__Container'>
			<h1 className='Orders__Heading'>
				Settings and Configuration Section
			</h1>
			<div className='Orders__BtnContainer'>
				<Button
					text='Store'
					onClick={() => setView(0)}
					className={
						view == 0
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Payment'
					onClick={() => setView(1)}
					className={
						view == 1
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Shipping'
					onClick={() => setView(2)}
					className={
						view == 2
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Tax'
					onClick={() => setView(3)}
					className={
						view == 3
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='User'
					onClick={() => setView(4)}
					className={
						view == 4
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
			</div>

			<div>{showView()}</div>
		</div>
	);
};

export default Reports;
