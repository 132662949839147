import React, { useState } from 'react';

const PaymentSettings = () => {
	const [refundPaymentId, setRefundPaymentId] =
		useState('');

	const paymentGateways = [
		{ id: 'pg1', name: 'PayPal' },
		{ id: 'pg2', name: 'Stripe' },
		{ id: 'pg3', name: 'Square' },
	];

	const transactionLogs = [
		{
			id: 't1',
			paymentGatewayId: 'pg1',
			paymentId: 'p1',
			amount: 100,
		},
		{
			id: 't2',
			paymentGatewayId: 'pg2',
			paymentId: 'p2',
			amount: 200,
		},
		{
			id: 't3',
			paymentGatewayId: 'pg3',
			paymentId: 'p3',
			amount: 150,
		},
	];

	const handleRefundSubmit = (event) => {
		event.preventDefault();
		alert(
			'Initiated refund for payment ID: ' + refundPaymentId
		);
	};

	return (
		<div className='Settings__Form'>
			<h2>Payment Settings</h2>

			<div className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					Payment Gateways
				</h3>
				<div className='Settings__Table'>
					<table>
						<thead>
							<tr>
								<th>ID</th>
								<th>Name</th>
							</tr>
						</thead>
						<tbody>
							{paymentGateways.map((gateway) => (
								<tr key={gateway.id}>
									<td>{gateway.id}</td>
									<td>{gateway.name}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h3 className='text-3xl font-semibold mb-2'>
					Transaction Logs
				</h3>
				<div className='Settings__Table'>
					<table>
						<thead>
							<tr>
								<th>Transaction ID</th>
								<th>Payment Gateway ID</th>
								<th>Payment ID</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{transactionLogs.map((log) => (
								<tr key={log.id}>
									<td>{log.id}</td>
									<td>{log.paymentGatewayId}</td>
									<td>{log.paymentId}</td>
									<td>${log.amount}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div>
				<h3 className='text-3xl font-semibold mb-2'>
					Refund Settings
				</h3>
				<form
					onSubmit={handleRefundSubmit}
					className='flex flex-col'>
					<label className='mb-2'>
						Payment ID:
						<input
							type='text'
							value={refundPaymentId}
							onChange={(e) =>
								setRefundPaymentId(e.target.value)
							}
							className='border border-gray-300 rounded-md px-3 py-2 mt-1 w-full'
							required
						/>
					</label>
					<button
						type='submit'
						className='bg-blue-500 text-white py-2 px-4 rounded-md mt-4'>
						Initiate Refund
					</button>
				</form>
			</div>
		</div>
	);
};

export default PaymentSettings;
