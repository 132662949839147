import { useState } from 'react';

const products = [
	{
		id: 'P001',
		name: 'Product One',
		category: 'Category A',
		price: '$100.00',
		stockStatus: 'In Stock',
		visibility: 'Visible',
	},
	{
		id: 'P002',
		name: 'Product Two',
		category: 'Category B',
		price: '$150.00',
		stockStatus: 'Out of Stock',
		visibility: 'Hidden',
	},
	// Add more dummy products as needed
];

const ProductsList = () => {
	const [visibleProducts, setVisibleProducts] =
		useState(10);
	const [searchQuery, setSearchQuery] = useState('');

	const loadMoreProducts = () => {
		setVisibleProducts((prev) =>
			Math.min(prev + 5, products.length)
		);
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value.toLowerCase());
	};

	const filteredProducts = products.filter(
		(product) =>
			product.name.toLowerCase().includes(searchQuery) ||
			product.category.toLowerCase().includes(searchQuery)
	);

	// field names of records
	// const enum_products = ["id", "name", "category", "price", "stockStatus", "visibility"]

	// const sortByColumn = (index) => {
	//     // sorting logic
	// };

	const table_head = [
		'Product ID',
		'Name',
		'Category',
		'Price',
		'Status',
		'Visibility',
	];

	return (
		<main className='products'>
			<div className='products__Top'>
				<div className='products__Searchbar'>
					<input
						type='text'
						placeholder='Search...'
						value={searchQuery}
						onChange={handleSearch}
					/>
				</div>
			</div>
			<div className='products__Table'>
				<table>
					<thead>
						<tr>
							{table_head.map((title, index) => (
								<th key={index}>
									<div>
										{title}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											className='size-6'
										// onClick={() => sortByColumn(index)}
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
											/>
										</svg>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{filteredProducts
							.slice(0, visibleProducts)
							.map((item) => (
								<tr key={item.id}>
									<td>{item.id}</td>
									<td title={item.name}>{item.name}</td>
									<td>{item.category}</td>
									<td>{item.price}</td>
									<td>{item.stockStatus}</td>
									<td>$ {item.visibility}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			{visibleProducts < filteredProducts.length && (
				<div
					className='products__LoadMore'
					onClick={loadMoreProducts}>
					<p>Load More...</p>
				</div>
			)}
		</main>
	);
};

export default ProductsList;
