import { put, takeLatest } from 'redux-saga/effects';
import {
	fetchCategory,
	fetchCategoryFailure,
	fetchCategorySuccess,
	fetchByor,
	fetchByorFailure,
	fetchByorSuccess,
} from '../reducers/categories';
import {
	getCategories,
	postByor,
} from '../../repositories/Category';
function* fetchCategorySaga(action) {
	try {
		const data = yield getCategories(action.payload).then(
			(resp) => resp
		);
		yield put(fetchCategorySuccess(data.data));
	} catch (error) {
		yield put(fetchCategoryFailure(error));
	}
}
export function* watchCategorySaga() {
	yield takeLatest(fetchCategory.type, fetchCategorySaga);
}

function* fetchByorSaga(action) {
	try {
		const data = yield postByor(action.payload).then(
			(resp) => resp
		);
		yield put(fetchByorSuccess(data.data));
	} catch (error) {
		yield put(fetchByorFailure(error));
	}
}
export function* watchByorSaga() {
	yield takeLatest(fetchByor.type, fetchByorSaga);
}
