import { Link } from 'react-router-dom';
import { Button } from '../../components';

const ErrorPage = () => {
	return (
		<main className='error'>
			<h1>404</h1>
			<div className='error__Image'>
				<img
					src='/ErrorPageImageOne.svg'
					alt='error-image'
				/>
			</div>
			<h4>Page Not Found</h4>
			<p>
				Sorry, the page you&apos;re looking for does not
				exist or has been moved please go back to the Home
				page
			</p>
			<Link to='/'>
				<Button
					type='button'
					text='Go back Home'
					className='button__Common'
				/>
			</Link>
		</main>
	);
};

export default ErrorPage;
