import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
	email: '',
	password: '',
	mobile: '',
};
const credsSlice = createSlice({
	name: 'cred',
	initialState: INITIAL_STATE,
	reducers: {
		setCred(state, action) {
			state.email = action.payload.email;
			state.password = action.payload.password;
			state.mobile = action.payload.mobile;
		},
	},
});
export const { setCred } = credsSlice.actions;
export const credsReducer = credsSlice.reducer;

const INITIAL_STATE_USER = {
	email: '',
	username: '',
	accessToken: '',
	refreshToken: '',
	userType: '',
	mobile: '',
};
const userCredsSlice = createSlice({
	name: 'userCreds',
	initialState: INITIAL_STATE_USER,
	reducers: {
		setUserCred(state, action) {
			state.email = action.payload.email;
			state.username = action.payload.username;
			state.userType = action.payload.userType;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
		},
		unUserSetCred(state) {
			state.email = '';
			state.username = '';
			state.accessToken = '';
			state.refreshToken = '';
			state.userType = '';
			state.mobile = '';
		},
	},
});
export const { setUserCred, unUserSetCred } =
	userCredsSlice.actions;
export const userCredsReducer = userCredsSlice.reducer;

const INITIAL_STATE1 = {
	data: null,
	error: null,
	isLoading: false,
};
const userLoginOtpSlice = createSlice({
	name: 'userLoginOtp',
	initialState: INITIAL_STATE1,
	reducers: {
		fetchOtpLogin(state, action) {
			state.isLoading = true;
		},
		fetchOtpLoginSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchOtpLoginFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchOtpLogin,
	fetchOtpLoginSuccess,
	fetchOtpLoginFailure,
} = userLoginOtpSlice.actions;
export const otpLoginReducer = userLoginOtpSlice.reducer;

const userRegisterOtpSlice = createSlice({
	name: 'userRegisterOtp',
	initialState: INITIAL_STATE1,
	reducers: {
		fetchOtpRegister(state, action) {
			state.isLoading = true;
		},
		fetchOtpRegisterSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchOtpRegisterFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchOtpRegister,
	fetchOtpRegisterSuccess,
	fetchOtpRegisterFailure,
} = userRegisterOtpSlice.actions;
export const otpRegisterReducer =
	userRegisterOtpSlice.reducer;

const userRegisterSlice = createSlice({
	name: 'userRegister',
	initialState: INITIAL_STATE1,
	reducers: {
		fetchRegister(state, action) {
			state.isLoading = true;
		},
		fetchRegisterSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchRegisterFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchRegister,
	fetchRegisterSuccess,
	fetchRegisterFailure,
} = userRegisterSlice.actions;
export const registerReducer = userRegisterSlice.reducer;

const userLoginSlice = createSlice({
	name: 'userLogin',
	initialState: INITIAL_STATE1,
	reducers: {
		fetchLogin(state, action) {
			console.log(action);
			state.isLoading = true;
		},
		fetchLoginSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchLoginFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchLogin,
	fetchLoginSuccess,
	fetchLoginFailure,
} = userLoginSlice.actions;
export const loginReducer = userLoginSlice.reducer;
