import React from 'react';
// import { PieChart } from '@mui/x-charts/PieChart';

const CustomerReport = () => {
	const customerReports = {
		acquisition: { value: 350, comparison: 15 },
		retentionRate: { value: 80, comparison: 3 },
		segments: [
			{ id: 0, label: 'Segment A', value: 30 },
			{ id: 1, label: 'Segment B', value: 50 },
			{ id: 2, label: 'Segment C', value: 20 },
		],
	};

	return (
		<div className='mb-8'>
			<h2 className='Reports__Heading'>Customer Reports</h2>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
				<div className='Report__Sections'>
					<h3>Customer Acquisition</h3>
					<p>
						New Customers:{' '}
						{customerReports.acquisition.value}
						<br />
						Comparison:{' '}
						{customerReports.acquisition.comparison}% from
						previous period
					</p>
				</div>
				<div className='Report__Sections'>
					<h3>Retention Rates</h3>
					<p>
						Retention Rate:{' '}
						{customerReports.retentionRate.value}%
						<br />
						Comparison:{' '}
						{customerReports.retentionRate.comparison}% from
						previous period
					</p>
				</div>
				<div className='Report__Sections col-span-2 items-center'>
					<h3>Customer Segments</h3>
					{/* <PieChart
						series={[
							{
								data: customerReports.segments,
							},
						]}
						width={400}
						height={400}
					/> */}
				</div>
			</div>
		</div>
	);
};

export default CustomerReport;
