import { useState } from "react";

const products = [
    {
        id: "1",
        product_name: "Product A",
        description: "This is product A",
        images: ["image1.jpg", "image2.jpg"],
        variants: [
            { price: "$100.00", sku: "A100", inventory: 10 },
        ],
    },
    {
        id: "2",
        product_name: "Product B",
        description: "This is product B",
        images: ["image3.jpg", "image4.jpg"],
        variants: [
            { price: "$50.00", sku: "B50", inventory: 5 },
        ],
    },
    // Add more dummy products as needed
];

const ProductsDetail = () => {
    const [productId, setProductId] = useState('');
    const [productDetails, setProductDetails] = useState(null);

    const handleProductIdChange = (e) => {
        setProductId(e.target.value);
    };

    const handleSearchProduct = () => {
        const foundProduct = products.find(product => product.id === productId);
        if (foundProduct) {
            setProductDetails(foundProduct);
        } else {
            alert(`Product with ID ${productId} not found.`);
        }
    };

    return (
        <div className="ProductDetails__Container">
            <div className="ProductDetails__InputForm">
                <label>Enter Product ID:</label>
                <input type="text" value={productId} onChange={handleProductIdChange} />
                <button onClick={handleSearchProduct}>Search</button>
            </div>

            {productDetails && (
                <div className="ProductDetails__Card">
                    <h2>Product Details</h2>
                    <p><strong>Product ID:</strong> {productDetails.id}</p>
                    <p><strong>Name:</strong> {productDetails.product_name}</p>
                    <p><strong>Description:</strong> {productDetails.description}</p>
                    <p><strong>Images:</strong> {productDetails.images.join(", ")}</p>
                    <div>
                        <h3>Variants</h3>
                        {productDetails.variants.map((variant, index) => (
                            <div key={index} className="ProductDetails__Variant">
                                <p><strong>Price:</strong> {variant.price}</p>
                                <p><strong>SKU:</strong> {variant.sku}</p>
                                <p><strong>Inventory:</strong> {variant.inventory}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductsDetail;
