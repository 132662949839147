import { put, takeLatest } from 'redux-saga/effects';
import {
	fetchBlog,
	fetchBlogFailure,
	fetchBlogSuccess,
	fetchDetailedBlog,
	fetchDetailedBlogFailure,
	fetchDetailedBlogSuccess,
} from './blog.reducer';
import {
	getBlogs,
	getDetailedBlog,
	postBlogs,
} from '../../../repositories/blog';
function* fetchBlogSaga(action) {
	try {
		const data = yield getBlogs(action.payload).then(
			(resp) => resp
		);
		yield put(fetchBlogSuccess(data.data));
	} catch (error) {
		yield put(fetchBlogFailure(error));
	}
}
export function* watchBlogSaga() {
	yield takeLatest(fetchBlog.type, fetchBlogSaga);
}

function* fetchDetailedBlogSaga(action) {
	try {
		const data = yield getDetailedBlog(action.payload).then(
			(resp) => resp
		);
		yield put(fetchDetailedBlogSuccess(data.data));
	} catch (error) {
		yield put(fetchDetailedBlogFailure(error));
	}
}
export function* watchDetailedBlogSaga() {
	yield takeLatest(
		fetchDetailedBlog.type,
		fetchDetailedBlogSaga
	);
}
