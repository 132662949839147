import { useState } from 'react';
import { Button } from '../../../components';
import SalesReport from './SalesReport';
import CustomerReport from './CustomerReport';
import ProductReport from './ProductReport';
import FinancialReport from './FinancialReport';

const Reports = () => {
	const [view, setView] = useState(0);

	const showView = () => {
		switch (view) {
			case 0:
				return <SalesReport />;
			case 1:
				return <CustomerReport />;
			case 2:
				return <ProductReport />;
			case 3:
				return <FinancialReport />;
		}
	};

	return (
		<div className='Orders__Container'>
			<h1 className='Orders__Heading'>Reports Section</h1>
			<div className='Orders__BtnContainer'>
				<Button
					text='Sales'
					onClick={() => setView(0)}
					className={
						view == 0
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Customer'
					onClick={() => setView(1)}
					className={
						view == 1
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Product'
					onClick={() => setView(2)}
					className={
						view == 2
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
				<Button
					text='Financial'
					onClick={() => setView(3)}
					className={
						view == 3
							? 'Orders__NavBtns Orders__BtnActive'
							: 'Orders__NavBtns'
					}
				/>
			</div>

			<div>{showView()}</div>
		</div>
	);
};

export default Reports;
