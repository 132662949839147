import React, { useState } from 'react';

const CustomerSupport = () => {
	const [tickets, setTickets] = useState([
		{
			ticketId: 't001',
			customerName: 'John Doe',
			issue: 'Login issue',
			status: 'Open',
			timestamp: '2024-07-01 10:00',
		},
		{
			ticketId: 't002',
			customerName: 'Jane Smith',
			issue: 'Payment failure',
			status: 'Closed',
			timestamp: '2024-07-02 14:30',
		},
	]);

	const [chatLogs, setChatLogs] = useState([
		{
			chatId: 'c001',
			customerName: 'John Doe',
			message: 'I cannot log in to my account.',
			timestamp: '2024-07-01 10:01',
		},
		{
			chatId: 'c002',
			customerName: 'Jane Smith',
			message: 'My payment failed during checkout.',
			timestamp: '2024-07-02 14:32',
		},
	]);

	const [responseTimes, setResponseTimes] = useState([
		{
			ticketId: 't001',
			avgResponseTime: '5 minutes',
			resolutionTime: '1 hour',
		},
		{
			ticketId: 't002',
			avgResponseTime: '10 minutes',
			resolutionTime: '2 hours',
		},
	]);

	return (
		<div className='container mx-auto p-4'>
			<h1 className='text-4xl font-bold mb-8'>
				Customer Support
			</h1>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Ticket System
				</h2>
				<div className='Support__Table'>
					<table>
						<thead>
							<tr>
								<th>Ticket ID</th>
								<th>Customer Name</th>
								<th>Issue</th>
								<th>Status</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{tickets.map((ticket, index) => (
								<tr key={index}>
									<td>{ticket.ticketId}</td>
									<td>{ticket.customerName}</td>
									<td>{ticket.issue}</td>
									<td>{ticket.status}</td>
									<td>{ticket.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Chat Logs
				</h2>
				<div className='Support__Table'>
					<table>
						<thead>
							<tr>
								<th>Chat ID</th>
								<th>Customer Name</th>
								<th>Message</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{chatLogs.map((log, index) => (
								<tr key={index}>
									<td>{log.chatId}</td>
									<td>{log.customerName}</td>
									<td>{log.message}</td>
									<td>{log.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Response Times
				</h2>
				<div className='Support__Table'>
					<table>
						<thead>
							<tr>
								<th>Ticket ID</th>
								<th>Average Response Time</th>
								<th>Resolution Time</th>
							</tr>
						</thead>
						<tbody>
							{responseTimes.map((response, index) => (
								<tr key={index}>
									<td>{response.ticketId}</td>
									<td>{response.avgResponseTime}</td>
									<td>{response.resolutionTime}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CustomerSupport;
