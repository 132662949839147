import React from 'react';

const CouponsTable = () => {
	const coupons = [
		{ code: 'SAVE10', status: 'Active', discount: '10%' },
		{ code: 'WELCOME', status: 'Expired', discount: '15%' },
		{
			code: 'FREESHIP',
			status: 'Active',
			discount: 'Free Shipping',
		},
		{ code: 'NEWYEAR', status: 'Expired', discount: '20%' },
	];

	return (
		<div className='marketing__Table'>
			<table>
				<thead>
					<tr>
						<th>Coupon Code</th>
						<th>Status</th>
						<th>Discount</th>
					</tr>
				</thead>
				<tbody>
					{coupons.map((coupon, index) => (
						<tr key={index}>
							<td>{coupon.code}</td>
							<td>{coupon.status}</td>
							<td>{coupon.discount}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default CouponsTable;
