import React from 'react';
// import { PieChart } from '@mui/x-charts/PieChart';

const ProductReport = () => {
	const productReports = {
		topSelling: [
			{
				id: 0,
				label: 'Product 1',
				performance: { rating: 4.5 },
				inventoryTurnover: 5,
			},
			{
				id: 1,
				label: 'Product 2',
				performance: { rating: 4.2 },
				inventoryTurnover: 4,
			},
			{
				id: 2,
				label: 'Product 3',
				performance: { rating: 4.8 },
				inventoryTurnover: 6,
			},
		],
	};

	const renderProductTable = () => (
		<div className='Report__Table'>
			<h3 className='text-3xl font-semibold mb-8'>
				Product Data Table
			</h3>
			<table>
				<thead>
					<tr>
						<th scope='col'>Product ID</th>
						<th scope='col'>Product Name</th>
						<th scope='col'>
							Product Performance (Rating)
						</th>
						<th scope='col'>Inventory Turnover</th>
					</tr>
				</thead>
				<tbody>
					{productReports.topSelling.map((product) => (
						<tr key={product.id}>
							<td>{product.id}</td>
							<td>{product.label}</td>
							<td>{product.performance.rating}/5</td>
							<td>
								{product.inventoryTurnover} times per month
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);

	return (
		<div className='mb-8'>
			<h2 className='Reports__Heading'>Product Reports</h2>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
				<div className='Report__Sections'>
					<h3>Top-selling Products</h3>
					<div className='Report__Sections col-span-2 items-center'>
						{/* <PieChart
							series={[
								{
									data: [
										{
											id: 0,
											label: 'Product 1',
											value: 4.5 * 360,
										},
										{
											id: 1,
											label: 'Product 2',
											value: 4.2 * 360,
										},
										{
											id: 2,
											label: 'Product 3',
											value: 4.8 * 360,
										},
									],
								},
							]}
							width={400}
							height={400}
						/> */}
					</div>
				</div>
				<div className='Report__Sections'>
					<h3>Product Performance</h3>
					<p>
						Textual information about product performance.
					</p>
				</div>
				<div className='col-span-2'>
					{renderProductTable()}
				</div>
			</div>
		</div>
	);
};

export default ProductReport;
