import React, { useState } from 'react';

const CustomerNotif = () => {
	const [orderConfirmations, setOrderConfirmations] =
		useState([
			{
				notificationId: 'n001',
				orderId: '001',
				message: 'Your order has been confirmed.',
				timestamp: '2024-07-01 10:00',
			},
			{
				notificationId: 'n002',
				orderId: '002',
				message: 'Your order has been confirmed.',
				timestamp: '2024-07-02 14:30',
			},
		]);

	const [shippingUpdates, setShippingUpdates] = useState([
		{
			notificationId: 'n003',
			orderId: '001',
			message: 'Your order has been shipped.',
			timestamp: '2024-07-02 09:00',
		},
		{
			notificationId: 'n004',
			orderId: '003',
			message: 'Your order has been shipped.',
			timestamp: '2024-07-03 15:45',
		},
	]);

	const [promotionalMessages, setPromotionalMessages] =
		useState([
			{
				notificationId: 'n005',
				message: 'Get 20% off on your next purchase!',
				timestamp: '2024-07-01 12:00',
			},
			{
				notificationId: 'n006',
				message: 'Exclusive offer: Buy one, get one free!',
				timestamp: '2024-07-02 18:30',
			},
		]);

	return (
		<div className='container mx-auto p-4'>
			<h1 className='text-4xl font-bold mb-8'>
				Customer Notifications
			</h1>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Order Confirmations
				</h2>
				<div className='Notif__Table'>
					<table>
						<thead>
							<tr>
								<th>Notification ID</th>
								<th>Order ID</th>
								<th>Message</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{orderConfirmations.map(
								(confirmation, index) => (
									<tr key={index}>
										<td>{confirmation.notificationId}</td>
										<td>{confirmation.orderId}</td>
										<td>{confirmation.message}</td>
										<td>{confirmation.timestamp}</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Shipping Updates
				</h2>
				<div className='Notif__Table'>
					<table>
						<thead>
							<tr>
								<th>Notification ID</th>
								<th>Order ID</th>
								<th>Message</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{shippingUpdates.map((update, index) => (
								<tr key={index}>
									<td>{update.notificationId}</td>
									<td>{update.orderId}</td>
									<td>{update.message}</td>
									<td>{update.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<div className='mb-8'>
				<h2 className='text-3xl font-semibold mb-4'>
					Promotional Messages
				</h2>
				<div className='Notif__Table'>
					<table>
						<thead>
							<tr>
								<th>Notification ID</th>
								<th>Message</th>
								<th>Timestamp</th>
							</tr>
						</thead>
						<tbody>
							{promotionalMessages.map((message, index) => (
								<tr key={index}>
									<td>{message.notificationId}</td>
									<td>{message.message}</td>
									<td>{message.timestamp}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CustomerNotif;
