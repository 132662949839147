import React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';

const FinancialReport = () => {
	const financialReports = {
		revenue: { value: 45000, comparison: 12 },
		profitMargins: { value: 20, comparison: 2 },
		expenses: { value: 25000, comparison: -4 },
	};

	return (
		<div className='mb-8'>
			<h2 className='Reports__Heading'>
				Financial Reports
			</h2>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
				<div className='Report__Sections'>
					<h3>Revenue</h3>
					<p>
						Total Revenue: ${financialReports.revenue.value}
						<br />
						Comparison:{' '}
						{financialReports.revenue.comparison}% from
						previous period
					</p>
					{/* <BarChart
						xAxis={[
							{ scaleType: 'band', data: ['Revenue'] },
						]}
						series={[
							{ data: [financialReports.revenue.value] },
						]}
						width={400}
						height={300}
					/> */}
				</div>
				<div className='Report__Sections'>
					<h3>Profit Margins</h3>
					<p>
						Profit Margin:{' '}
						{financialReports.profitMargins.value}%
						<br />
						Comparison:{' '}
						{financialReports.profitMargins.comparison}%
						from previous period
					</p>
					{/* <BarChart
						xAxis={[
							{
								scaleType: 'band',
								data: ['Profit Margin'],
							},
						]}
						series={[
							{
								data: [
									financialReports.profitMargins.value,
								],
							},
						]}
						width={400}
						height={300}
					/> */}
				</div>
				<div className='Report__Sections'>
					<h3>Expenses</h3>
					<p>
						Total Expenses: $
						{financialReports.expenses.value}
						<br />
						Comparison:{' '}
						{financialReports.expenses.comparison}% from
						previous period
					</p>
					{/* <BarChart
						xAxis={[
							{ scaleType: 'band', data: ['Expenses'] },
						]}
						series={[
							{ data: [financialReports.expenses.value] },
						]}
						width={400}
						height={300}
					/> */}
				</div>
			</div>
		</div>
	);
};

export default FinancialReport;
