import { apiClient } from './apiclient';

export const getBlogs = async (params) => {
	const apiUrl = '/blogs';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};

export const getDetailedBlog = async (params) => {
	const apiUrl = '/blogs/slug';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
