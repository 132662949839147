import React, { useState } from 'react';

const StoreSettings = () => {
	const [storeName, setStoreName] = useState('');
	const [contactInfo, setContactInfo] = useState('');
	const [openHours, setOpenHours] = useState('');
	const [closeHours, setCloseHours] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log('Submitted Store Settings:', {
			storeName,
			contactInfo,
			openHours,
			closeHours,
		});
	};

	return (
		<form
			onSubmit={handleSubmit}
			className='Settings__Form'>
			<h2>Store Settings</h2>
			<label>
				Store Name:
				<input
					type='text'
					value={storeName}
					onChange={(e) => setStoreName(e.target.value)}
					required
				/>
			</label>
			<label>
				Contact Information:
				<input
					type='text'
					value={contactInfo}
					onChange={(e) => setContactInfo(e.target.value)}
					required
				/>
			</label>
			<label>
				Business Hours:
				<div className='flex items-center gap-10'>
					<div className='flex gap-4 items-center'>
						<span>Open:</span>
						<input
							type='time'
							value={openHours}
							onChange={(e) => setOpenHours(e.target.value)}
							required
						/>
					</div>

					<div className='flex gap-4 items-center'>
						<span>Close:</span>
						<input
							type='time'
							value={closeHours}
							onChange={(e) =>
								setCloseHours(e.target.value)
							}
							required
						/>
					</div>
				</div>
			</label>
			<button type='submit'>Save Settings</button>
		</form>
	);
};

export default StoreSettings;
