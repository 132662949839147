import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';

const Banner = ({ header, info, path, btnText }) => {
	return (
		<section className='banner'>
			<div className='banner__Container '>
				<div className='banner__Left'>
					<h1>{header}</h1>
					<p>
						{info}
					</p>
				</div>
				<div className='banner__Right'>
					<Link to={path}>
						<Button
							text={btnText}
							className='button__White max-lg:rounded-[0.2rem]'
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Banner;
