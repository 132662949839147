import { apiClient } from './apiclient';

export const postCategories = async (payload) => {
	const apiUrl = '/category/admin';
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const postSubCategories = async (payload) => {
	const apiUrl = '/category/admin/sub';
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const getCategories = async (params) => {
	const apiUrl = '/category';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
export const postByor = async (payload) => {
	const apiUrl = '/byor';

	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: payload,
	});
	return res;
};
