import React from 'react';

const EmailCampaignsTable = () => {
	const campaigns = [
		{
			name: 'Spring Sale',
			openRate: '45%',
			clickThroughRate: '12%',
			conversionRate: '5%',
		},
		{
			name: 'Winter Specials',
			openRate: '50%',
			clickThroughRate: '10%',
			conversionRate: '4%',
		},
		{
			name: 'Black Friday',
			openRate: '70%',
			clickThroughRate: '20%',
			conversionRate: '10%',
		},
		{
			name: 'Cyber Monday',
			openRate: '65%',
			clickThroughRate: '15%',
			conversionRate: '8%',
		},
	];

	return (
		<div className='marketing__Table'>
			<table>
				<thead>
					<tr>
						<th>Campaign Name</th>
						<th>Open Rate</th>
						<th>Click-Through Rate</th>
						<th>Conversion Rate</th>
					</tr>
				</thead>
				<tbody>
					{campaigns.map((campaign, index) => (
						<tr key={index}>
							<td>{campaign.name}</td>
							<td>{campaign.openRate}</td>
							<td>{campaign.clickThroughRate}</td>
							<td>{campaign.conversionRate}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default EmailCampaignsTable;
