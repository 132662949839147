import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
	data: null,
	error: null,
	isLoading: false,
};
const blogSlice = createSlice({
	name: 'blog',
	initialState: INITIAL_STATE,
	reducers: {
		fetchBlog(state, action) {
			state.isLoading = true;
		},
		fetchBlogSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchBlogFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchBlog,
	fetchBlogSuccess,
	fetchBlogFailure,
} = blogSlice.actions;
export const blogReducer = blogSlice.reducer;

const detailedBlogSlice = createSlice({
	name: 'detailedBlog',
	initialState: INITIAL_STATE,
	reducers: {
		fetchDetailedBlog(state, action) {
			state.isLoading = true;
		},
		fetchDetailedBlogSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchDetailedBlogtFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchDetailedBlog,
	fetchDetailedBlogSuccess,
	fetchDetailedBlogFailure,
} = detailedBlogSlice.actions;
export const BlogDetailedReducer =
	detailedBlogSlice.reducer;
