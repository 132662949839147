import React, { useState } from 'react';

const TaxSettings = () => {
	const [taxSettings, setTaxSettings] = useState([
		{
			id: 'tx1',
			name: 'Standard Tax',
			rate: 10.0,
			zones: ['Zone 1', 'Zone 2'],
		},
		{
			id: 'tx2',
			name: 'Reduced Tax',
			rate: 5.0,
			zones: ['Zone 1', 'Zone 3'],
		},
		{
			id: 'tx3',
			name: 'Zero Tax',
			rate: 0.0,
			zones: ['Zone 2', 'Zone 3'],
		},
	]);
	const [newTax, setNewTax] = useState({
		id: '',
		name: '',
		rate: 0,
		zones: '',
	});

	const handleAddOrUpdateTax = () => {
		const existingIndex = taxSettings.findIndex(
			(tax) => tax.id === newTax.id
		);
		if (existingIndex >= 0) {
			const updatedSettings = [...taxSettings];
			updatedSettings[existingIndex] = {
				...newTax,
				zones: newTax.zones.split(', '),
			};
			setTaxSettings(updatedSettings);
		} else {
			setTaxSettings([
				...taxSettings,
				{ ...newTax, zones: newTax.zones.split(', ') },
			]);
		}
		setNewTax({ id: '', name: '', rate: 0, zones: '' });
	};

	const handleDeleteTax = (id) => {
		// if (
		// 	confirm(
		// 		'Are you sure you want to delete this record?'
		// 	)
		// )
		setTaxSettings(
			taxSettings.filter((tax) => tax.id !== id)
		);
	};

	return (
		<div className='mt-4 rounded-sm shadow-sm'>
			<h2 className='text-4xl font-semibold mb-4'>
				Tax Settings
			</h2>
			<div className='Settings__Table'>
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Tax Name</th>
							<th>Rate (%)</th>
							<th>Zones</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{taxSettings.map((tax) => (
							<tr key={tax.id}>
								<td>{tax.id}</td>
								<td>{tax.name}</td>
								<td>{tax.rate.toFixed(2)}</td>
								<td>{tax.zones.join(', ')}</td>
								<td>
									<button
										className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2'
										onClick={() =>
											setNewTax({
												...tax,
												zones: tax.zones.join(', '),
											})
										}>
										Update
									</button>
									<button
										className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'
										onClick={() => handleDeleteTax(tax.id)}>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className='Settings__Form mt-10'>
				<h3 className='text-3xl font-semibold mb-4'>
					Add / Update Tax Rate
				</h3>
				<div>
					<label htmlFor='id'>ID</label>
					<input
						type='text'
						id='id'
						value={newTax.id}
						onChange={(e) =>
							setNewTax({ ...newTax, id: e.target.value })
						}
					/>
				</div>
				<div>
					<label htmlFor='name'>Tax Name</label>
					<select
						value={newTax.name}
						onChange={(e) =>
							setNewTax({ ...newTax, name: e.target.value })
						}>
						<option value='Standard Tax'>
							Standard Tax
						</option>
						<option value='Reduced Tax'>Reduced Tax</option>
						<option value='	Zero Tax'>Zero Tax</option>
					</select>
				</div>
				<div>
					<label htmlFor='rate'>Rate (%)</label>
					<input
						type='number'
						id='rate'
						value={newTax.rate}
						onChange={(e) =>
							setNewTax({
								...newTax,
								rate: parseFloat(e.target.value),
							})
						}
					/>
				</div>
				<div>
					<label htmlFor='zones'>
						Zones (comma separated)
					</label>
					<input
						type='text'
						id='zones'
						value={newTax.zones}
						onChange={(e) =>
							setNewTax({
								...newTax,
								zones: e.target.value,
							})
						}
					/>
				</div>
				<button
					className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'
					onClick={handleAddOrUpdateTax}>
					Save
				</button>
			</div>
		</div>
	);
};

export default TaxSettings;
