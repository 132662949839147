import { useState } from 'react';
import { Sidebar } from '../../../components';
import Feedback from '../Feedback/Feedback.router';
import Customers from '../Customers/List/Customers.router';
import CustomerDetails from '../Customers/Details/CustomerDetails.router';
import Overview from '../Overview/Overview.router';
import Orders from '../Orders/Orders.router';
import Products from '../Products/Products.router';
import Marketing from '../Marketing/Marketing.router';
import Reports from '../Reports/Reports.router';
import Settings from '../Settings/Settings.router';
import SystemAlerts from '../Notifications/SystemAlerts.router';
import CustomerNotif from '../Notifications/CustomerNotif.router';
import CustomerSupport from '../Support/Support.router';

const AdminDashboard = () => {
	const [activeTab, setActiveTab] = useState('Dashboard');

	const renderContent = () => {
		switch (activeTab) {
			case 'Dashboard':
				return <Overview />;
			case 'Orders':
				return <Orders />;
			case 'Products':
				return <Products />;
			case 'Details':
				return <Customers />;
			case 'Manage':
				return <CustomerDetails />;
			case 'Marketing':
				return <Marketing />;
			case 'Reports':
				return <Reports />;
			case 'Settings':
				return <Settings />;
			case 'System Alerts':
				return <SystemAlerts />;
			case 'Customer Notifications':
				return <CustomerNotif />;
			case 'Customer Support':
				return <CustomerSupport />;
			case 'Feedback and Reviews':
				return <Feedback />;
			default:
				return <div>Select a tab</div>;
		}
	};

	return (
		<main className='adminDashboard'>
			<section className='adminDashboard__Wrapper'>
				<div className='adminDashboard__Sidebar'>
					<Sidebar onTabChange={setActiveTab} />
				</div>
				<section className='adminDashboard__Body'>
					{renderContent()}
				</section>
			</section>
		</main>
	);
};

export default AdminDashboard;
