import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Capabilities = ({ data, buttonsData }) => {
	const [hoveredButton, setHoveredButton] = useState(null);

	const handleMouseEnter = (index) => {
		setHoveredButton(index);
	};

	const handleMouseLeave = () => {
		setHoveredButton(null);
	};

	return (
		<main className='capabilities'>
			<Helmet>
				<title>{data.title} - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
			</Helmet>
			{/* Banner */}
			<section className='capabilities__Banner'>
				<img
					src={data.img}
					alt='capabilities-banner'
				/>
			</section>
			{/* Details Section */}
			<section className='capabilities__Details'>
				<h3>{data.h3}</h3>
				<p>{data.subDetails}</p>
				<h1>
					{data.h1} <span>{data.span}</span>
				</h1>
				<p>
					{data.paraOne}<br /><br />
					{data.paraTwo}<br /><br />
					{data.paraThree}
				</p>
			</section>
			{/* Tags */}
			<section className='capabilities__Tags'>
				<div className='capabilities__RowOne'>
					{buttonsData.slice(0, 4).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
				<div className='capabilities__RowTwo'>
					{buttonsData.slice(4, 7).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() =>
								handleMouseEnter(index + 4)
							}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index + 4
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
				<div className='capabilities__RowThree'>
					{buttonsData.slice(7, 9).map((button, index) => (
						<button
							key={button.id}
							onMouseEnter={() =>
								handleMouseEnter(index + 7)
							}
							onMouseLeave={handleMouseLeave}>
							<img
								src={
									hoveredButton === index + 7
										? '/CapabilitiesTickIcon.svg'
										: '/PlusIcon.svg'
								}
								alt='icon-one'
							/>
							{button.text}
						</button>
					))}
				</div>
			</section>
		</main>
	);
};

export default Capabilities;
