import { put, takeLatest } from "redux-saga/effects";
import {
  fetchOtpLogin,
  fetchOtpLoginFailure,
  fetchOtpLoginSuccess,
  fetchOtpRegister,
  fetchOtpRegisterFailure,
  fetchOtpRegisterSuccess,
  fetchRegister,
  fetchRegisterFailure,
  fetchRegisterSuccess,
  fetchLogin,
  fetchLoginFailure,
  fetchLoginSuccess,
} from "../reducers/creds";
import {
  postInitiateSignin,
  postSignin,
  postInitiateSignup,
  postSignup,
} from "../../repositories/auth";
function* fetchOtpLoginSaga(action) {
  try {
    const data = yield postInitiateSignin(action.payload).then((resp) => resp);
    yield put(fetchOtpLoginSuccess(data.data));
  } catch (error) {
    yield put(fetchOtpLoginFailure(error));
  }
}
export function* watchOtpLoginSaga() {
  yield takeLatest(fetchOtpLogin.type, fetchOtpLoginSaga);
}

function* fetchOtpRegisterSaga(action) {
  try {
    const data = yield postInitiateSignup(action.payload).then((resp) => resp);
    yield put(fetchOtpRegisterSuccess(data.data));
  } catch (error) {
    yield put(fetchOtpRegisterFailure(error));
  }
}
export function* watchOtpRegisterSaga() {
  yield takeLatest(fetchOtpRegister.type, fetchOtpRegisterSaga);
}

function* fetchRegisterSaga(action) {
  try {
    const data = yield postSignup(action.payload).then((resp) => resp);
    yield put(fetchRegisterSuccess(data.data));
  } catch (error) {
    yield put(fetchRegisterFailure(error));
  }
}
export function* watchRegisterSaga() {
  yield takeLatest(fetchRegister.type, fetchRegisterSaga);
}

function* fetchLoginSaga(action) {
  try {
    const data = yield postSignin(action.payload).then((resp) => resp);
    yield put(fetchLoginSuccess(data.data));
  } catch (error) {
    yield put(fetchLoginFailure(error));
  }
}
export function* watchLoginSaga() {
  yield takeLatest(fetchLogin.type, fetchLoginSaga);
}
