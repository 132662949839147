import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
	data: null,
	error: null,
	isLoading: false,
};
const postSlice = createSlice({
	name: 'posts',
	initialState: INITIAL_STATE,
	reducers: {
		fetchPost(state, action) {
			state.isLoading = true;
		},
		fetchPostSuccess(state, action) {
			state.isLoading = false;
			state.data = action.payload;
		},
		fetchPostFailure(state, action) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export const {
	fetchPost,
	fetchPostSuccess,
	fetchPostFailure,
} = postSlice.actions;
export const postReducer = postSlice.reducer;