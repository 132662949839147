
import { put, takeLatest } from 'redux-saga/effects';
import { getPosts } from '../../repositories/Report';
import { fetchPost,fetchPostFailure,fetchPostSuccess } from '../reducers/posts';
function* fetchPostSaga(action) {
	try {
		const data = yield getPosts(action.payload).then(
			(resp) => resp
		);
		yield put(fetchPostSuccess(data.data));
	} catch (error) {
		yield put(fetchPostFailure(error));
	}
}
export function* watchPostSaga() {
	yield takeLatest(fetchPost.type, fetchPostSaga);
}
