import React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';

const SalesReport = () => {
	const salesReports = {
		daily: { value: 1200, comparison: 10 },
		weekly: { value: 8500, comparison: -5 },
		monthly: { value: 35000, comparison: 8 },
		quarterly: { value: 110000, comparison: 15 },
		yearly: { value: 420000, comparison: 6 },
	};

	// Example data for bar chart
	const salesData = [1200, 8500, 35000, 110000, 420000]; // Sales amounts
	const periods = [
		'Daily',
		'Weekly',
		'Monthly',
		'Quarterly',
		'Yearly',
	]; // Period labels

	return (
		<div className='mb-8'>
			<h2 className='Reports__Heading'>Sales Reports</h2>
			<div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
				<div className='Report__Sections'>
					<h3>Daily Sales</h3>
					<p>
						Total Sales: ${salesReports.daily.value}
						<br />
						Comparison: {salesReports.daily.comparison}%
						from previous period
					</p>
				</div>
				<div className='Report__Sections'>
					<h3>Weekly Sales</h3>
					<p>
						Total Sales: ${salesReports.weekly.value}
						<br />
						Comparison: {salesReports.weekly.comparison}%
						from previous period
					</p>
				</div>
				<div className='Report__Sections'>
					<h3>Monthly Sales</h3>
					<p>
						Total Sales: ${salesReports.monthly.value}
						<br />
						Comparison: {salesReports.monthly.comparison}%
						from previous period
					</p>
				</div>
				<div className='Report__Sections'>
					<h3>Quarterly Sales</h3>
					<p>
						Total Sales: ${salesReports.quarterly.value}
						<br />
						Comparison: {salesReports.quarterly.comparison}%
						from previous period
					</p>
				</div>
				<div className='Report__Sections'>
					<h3>Yearly Sales</h3>
					<p>
						Total Sales: ${salesReports.yearly.value}
						<br />
						Comparison: {salesReports.yearly.comparison}%
						from previous period
					</p>
				</div>
				<div className='col-span-3 Report__Sections'>
					<h3>Sales Trends</h3>
					{/* <BarChart
						xAxis={[{ scaleType: 'band', data: periods }]}
						series={[{ data: salesData }]}
						sx={{
							height: '400px',
						}}
						height={400}
					/> */}
				</div>
			</div>
		</div>
	);
};

export default SalesReport;
