// src/reducers/index.js
import { combineReducers } from 'redux';
import { byorReducer, categoryReducer } from './categories';
import {
	publishReportReducer,
	reportDetailedReducer,
	reportReducer,
} from './report';
import { postReducer } from './posts';
import {
	credsReducer,
	loginReducer,
	otpLoginReducer,
	otpRegisterReducer,
	registerReducer,
	userCredsReducer,
} from './creds';
import {
	BlogDetailedReducer,
	blogReducer,
} from '../../pages/Blogs/Blog/blog.reducer';

export const rootReducer = combineReducers({
	category: categoryReducer,
	report: reportReducer,
	detailedReport: reportDetailedReducer,
	publishReport: publishReportReducer,
	post: postReducer,
	byor: byorReducer,
	cred: credsReducer,
	userCreds: userCredsReducer,
	otpLogin: otpLoginReducer,
	login: loginReducer,
	otpRegister: otpRegisterReducer,
	register: registerReducer,
	blog: blogReducer,
	blogDetailed: BlogDetailedReducer,
});
